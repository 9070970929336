import React, { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';

import { useFetchForRemoteSelect, usePrevious } from '../../../hooks';
import { debounceIndexAccountRequest, indexAccountRequest } from '../../../requests/accounts';
import { debounceIndexAdminAccountRequest, indexAdminAccountRequest } from '../../../requests/admin/adminAccounts';
import { InputRemoteSelect } from '../../Utils/Select';
import {
  debounceIndexInsuredAccountRequest,
  indexInsuredAccountRequest
} from '../../../requests/insured/insuredAccounts';

const AccountRemoteSelector = ({ disabled, field, fromAdmin, modelName, tooltipText, userModule }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const { accountId, insuranceBrokerId } = getIn(values, modelName);
  const prevBrokerId = usePrevious(insuranceBrokerId);

  const getIndexRequest = () => {
    const requestMap = {
      admin: indexAdminAccountRequest,
      broker: indexAccountRequest,
      insured: indexInsuredAccountRequest
    };
    return requestMap[userModule];
  };

  const getDebouncedIndexRequest = () => {
    const requestMap = {
      admin: debounceIndexAdminAccountRequest,
      broker: debounceIndexAccountRequest,
      insured: debounceIndexInsuredAccountRequest
    };
    return requestMap[userModule];
  };

  const { options: accounts, selectedOption: selectedAccount, fetchOptions: fetchAccounts } = useFetchForRemoteSelect({
    indexRequest: getIndexRequest(),
    debouncedIndexRequest: getDebouncedIndexRequest(),
    value: accountId,
    initialParams: fromAdmin || userModule === 'insured' ? { insuranceBrokerId } : null,
    nestedDependency: insuranceBrokerId
  });

  const handleSelectedAccount = () => {
    const anyBrokerId = insuranceBrokerId || prevBrokerId;
    const initialPrevBrokerId = insuranceBrokerId && prevBrokerId === '';
    if (initialPrevBrokerId || (anyBrokerId && insuranceBrokerId === prevBrokerId)) return;

    setFieldValue(`${modelName}[accountId]`, '');
    setFieldValue(`${modelName}[accountName]`, '');
    setFieldValue(`${modelName}[executiveManagerId]`, '');
  };

  useEffect(handleSelectedAccount, [insuranceBrokerId, prevBrokerId]);

  return (
    <InputRemoteSelect
      abbr
      {...field}
      isClearable
      disabled={disabled}
      label="Cuenta"
      placeholder="Seleccionar cuenta"
      tooltipText={tooltipText}
      defaultOptions={accounts}
      value={selectedAccount}
      request={fetchAccounts}
      onChange={data => {
        setFieldValue(`${modelName}[accountName]`, data?.label || '');
        setFieldValue(field.name, data?.value || '');
        setFieldValue(`${modelName}[executiveManagerId]`, data?.executive_manager_id);
        setFieldValue(`${modelName}[responsibleEmail]`, data?.primary_contact_email);
      }}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    />
  );
};

export default AccountRemoteSelector;
