import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { InputRemoteSelect } from '../Utils/Select';
import { useFetchForRemoteSelect } from '../../hooks';
import {
  debounceIndexInsuranceBrokeresRequest,
  indexInsuranceBrokerRequest
} from '../../requests/admin/insuranceBrokers';
import {
  debounceIndexInsuredInsuranceBrokeresRequest,
  indexInsuredInsuranceBrokerRequest
} from '../../requests/insured/insuranceBrokers';

const InsuranceBrokerRemoteSelector = ({ field, modelName, tooltipText, fromAdmin }) => {
  const { errors, setFieldValue, values } = useFormikContext();

  const {
    options: insuranceBrokers,
    selectedOption: selectedInsuranceBroker,
    fetchOptions: fetchInsuranceBrokers
  } = useFetchForRemoteSelect({
    indexRequest: fromAdmin ? indexInsuranceBrokerRequest : indexInsuredInsuranceBrokerRequest,
    debouncedIndexRequest: fromAdmin
      ? debounceIndexInsuranceBrokeresRequest
      : debounceIndexInsuredInsuranceBrokeresRequest,
    value: getIn(values, `${modelName}[insuranceBrokerId]`)
  });

  return (
    <InputRemoteSelect
      abbr
      {...field}
      isClearable
      label="Corredor"
      placeholder="Seleccionar corredor"
      tooltipText={tooltipText}
      defaultOptions={insuranceBrokers}
      value={selectedInsuranceBroker}
      request={fetchInsuranceBrokers}
      onChange={data => {
        setFieldValue(field.name, data?.value || '');
      }}
      error={getIn(errors, field.name)}
      // touched={getIn(touched, field.name)}
      touched
    />
  );
};

export default InsuranceBrokerRemoteSelector;
