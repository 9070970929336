import React from 'react';
import memoize from 'memoize-one';

import { ReadMore } from '../../../components';
import { DataTableBadges, DatatableMainInfo } from '../../../components/Utils/DataTable';

const columns = memoize(() => {
  const leadColumns = [
    {
      name: 'Cuenta',
      selector: 'account',
      sortable: true,
      grow: '2',
      cell: ({ accountName, accountNationalIdentification, accountLegalPersonType }) => (
        <DatatableMainInfo
          icon={accountLegalPersonType.includes('natural') ? 'user' : 'organisation'}
          title={accountName}
          subtitle={accountNationalIdentification}
          iconSize="lg"
        />
      ),
      minWidth: '200px'
    },
    {
      name: 'Encargado',
      selector: 'responsible_email',
      sortable: true,
      grow: '1.5',
      cell: ({ responsibleEmail }) => responsibleEmail
    },
    {
      name: 'Corredor',
      selector: 'insurance_broker',
      sortable: true,
      grow: '1.5',
      cell: ({ insuranceBrokerCompanyName, insuranceBrokerName }) => (
        <DatatableMainInfo noIcon title={insuranceBrokerName} subtitle={insuranceBrokerCompanyName} />
      )
    },
    {
      name: 'Ramo',
      selector: 'insurance_category',
      sortable: true,
      grow: '2',
      cell: ({ insuranceCategoryName }) => insuranceCategoryName
    },
    {
      name: 'Materia',
      selector: 'subject_matter',
      sortable: true,
      grow: '1',
      cell: ({ subjectMatter }) => (
        <ReadMore more="+" title="Materia">
          {subjectMatter}
        </ReadMore>
      )
    },
    {
      name: 'Días de plazo',
      selector: 'deadline_days',
      sortable: true,
      grow: '1',
      cell: ({ deadlineDays, validityStart }) => (
        <DatatableMainInfo noIcon title={deadlineDays} subtitle={validityStart} />
      )
    },
    {
      cell: row => <DataTableBadges row={row} />,
      sortable: false,
      right: true,
      grow: '1.5'
    }
  ];

  return leadColumns;
});

export default columns;
