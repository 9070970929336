import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { authTypes } from '../actions/auth';
import API from '../services/api';
import { isSingleAdmin } from '../services/utils';
import runDefaultSaga, { updateJWT } from './default';

// SIGN IN SHARED METHODS

function* signInFailureCallback() {
  yield put({ type: authTypes.SIGN_IN_FAILURE });
}

// SIGN IN BY VERIFICATION CODE

const signInByVerificationCodeApiRequest = params =>
  API.put('/insured/sign_in_by_verification_codes/verify_code', params);

function* signInByVerificationCodeSuccessCallback(result, response, action) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    const authorization = response.headers.get('Authorization').split('Bearer ')[1];
    const customPath = action.from || '/insured/home';
    yield updateJWT(authorization);
    yield put({ type: authTypes.SIGN_IN_SUCCESS, result, response });
    yield put(push(customPath));
  }
}

const signInByVerificationCodeCallback = setSubmitting => setSubmitting(false);

export function* handleSignInByVerificationCode(action) {
  const callback = () => signInByVerificationCodeCallback(action.setSubmitting);
  yield runDefaultSaga(
    { request: signInByVerificationCodeApiRequest, params: action.params },
    (result, response) => signInByVerificationCodeSuccessCallback(result, response, action),
    signInFailureCallback,
    callback
  );
}

// SIGN IN BY PASSWORD

const signInByPasswordApiRequest = params => API.post('/login', params);

function* signInByPasswordSuccessCallback(result, response, action) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    const authorization = response.headers.get('Authorization').split('Bearer ')[1];
    const mainPath = isSingleAdmin(result) ? '/admin/home' : '/home';
    const customPath = action.from || mainPath;
    yield updateJWT(authorization);
    yield put({ type: authTypes.SIGN_IN_SUCCESS, result, response });
    yield put(push(customPath));
  }
}

export function* handleSignInByPassword(action) {
  yield runDefaultSaga(
    { request: signInByPasswordApiRequest, params: action.params },
    (result, response) => signInByPasswordSuccessCallback(result, response, action),
    signInFailureCallback
  );
}

// SIGN OUT
const signOutRequest = () => API.delete('/logout');
function* signOutSuccessCallback(result) {
  if (result.success) {
    yield localStorage.removeItem('jwt');
    yield put({ type: authTypes.SIGN_OUT_SUCCESS });
    yield put(push('/'));
  } else {
    throw new Error(result);
  }
}
function* signOutFailureCallback() {
  yield put({ type: authTypes.SIGN_OUT_FAILURE });
}
export function* signOut() {
  yield runDefaultSaga({ request: signOutRequest }, signOutSuccessCallback, signOutFailureCallback);
}

// VALIDATE TOKENS
const validateTokenRequest = () => API.get('/me');
function* validateTokensSuccessCallback(result, response) {
  if (result.logged_in) {
    yield put({
      type: authTypes.VALIDATE_TOKEN_SUCCESS,
      result,
      response,
      user: result.user_info
    });
  } else {
    yield put({ type: authTypes.CLEAR_AUTH_INFO });
    yield put(push('/login'));
  }
}
function* validateTokensFailureCallback() {
  yield put({ type: authTypes.CLEAR_AUTH_INFO });
  yield put(push('/login'));
}
export function* validateToken() {
  yield runDefaultSaga({ request: validateTokenRequest }, validateTokensSuccessCallback, validateTokensFailureCallback);
}

// DEFINE authSagas
export default function* authSagas() {
  yield takeEvery(authTypes.SIGN_IN_REQUEST, handleSignInByPassword);
  yield takeEvery(authTypes.SIGN_IN_BY_VERIFICATION_CODE_REQUEST, handleSignInByVerificationCode);
  yield takeEvery(authTypes.SIGN_OUT_REQUEST, signOut);
  yield takeEvery(authTypes.VALIDATE_TOKEN_REQUEST, validateToken);
}
