import React from 'react';
import { Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { FormikInputRadio } from '../../../Utils/Input';

const RadioField = ({ keyValue, details, modelName, errors, touched }) => {
  const { title: label, placeholder, required, tooltip, options } = details;

  const adaptedOptions = options.map(option => ({
    label: option,
    value: option
  }));

  return (
    <Col xs={12} md={6} className="mb-4 pl-5">
      <Field name={`${modelName}[${keyValue}]`}>
        {({ field }) => (
          <FormikInputRadio
            {...field}
            abbr={required}
            label={label}
            placeholder={placeholder || label}
            field={field}
            options={adaptedOptions}
            tooltipText={tooltip}
            fieldName={`${modelName}[${keyValue}]`}
            error={getIn(errors, `${modelName}[${keyValue}]`)}
            touched={getIn(touched, `${modelName}[${keyValue}]`)}
          />
        )}
      </Field>
    </Col>
  );
};

export default RadioField;
