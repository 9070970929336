import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import { FormHeader } from '../../../components';
import { HeaderTag } from '../../../components/Shared';
import {
  exportInsurancePolicyRequest,
  showAdminInsurancePolicyRequest
} from '../../../requests/admin/adminInsurancePolicies';
import { camelCaseEmptyStringRecursive, downloadFile, scrollToTop } from '../../../services/utils';
import basicInsurancePolicy from '../../InsurancePolicy/basicInsurancePolicy';
import InsurancePolicyInfo from '../../InsurancePolicy/InsurancePolicyInfo';
import { policyTypeOptions } from '../../InsurancePolicy/InsurancePoliciesForms/insurancePoliciesFormOptions';

const InsurancePolicyShow = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/insurance_policies';

  const [insurancePolicy, setInsurancePolicy] = useState(basicInsurancePolicy);
  const [isFetching, setIsFetching] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();

  const handleBreadcrumb = [
    { key: 1, name: 'Pólizas', href: modelUrl },
    { key: 2, name: 'Detalle de póliza' }
  ];

  const {
    accountName,
    insuranceEndorsementsAttributes,
    isAnnulled,
    isCancelled,
    policyNumber,
    policyType
  } = insurancePolicy;

  const currentPolicyType = policyTypeOptions.find(policyTypeOption => policyTypeOption.value === policyType) || {};
  const isContract = policyType === 'contract';

  const endorsementRequested = insuranceEndorsementsAttributes.find(object => object.status === 'requested') || false;
  const insuranceEndorsementPath = endorsementRequested ? `${endorsementRequested.id}/edit` : 'new';

  const showHeaderTag = isCancelled || isAnnulled || !isContract;
  const headerTagProps =
    isCancelled || isAnnulled
      ? { variant: 'danger', text: `${currentPolicyType.label} ${isCancelled ? 'cancelada' : 'anulada'}` }
      : { variant: 'secondary-light', text: currentPolicyType.label };

  const handleSuccessShow = response => {
    setInsurancePolicy(camelCaseEmptyStringRecursive(response.data));
  };

  const handleShowInsurancePolicy = () => {
    setIsFetching(true);
    showAdminInsurancePolicyRequest(id, {
      dispatch,
      successCallback: handleSuccessShow,
      callback: () => setIsFetching(false)
    });
  };

  const handleInsurancePolicyRequest = ({ xlsxName, ...extraProps }) => {
    const sendParams = { xlsxName, ...extraProps };
    exportInsurancePolicyRequest({
      dispatch,
      params: snakecaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  useEffect(scrollToTop, []);
  useEffect(handleShowInsurancePolicy, [moreData]);

  if (isFetching)
    return (
      <div
        className="containerSpinnerLoad"
        style={{ display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      title={`${accountName} - N° ${policyNumber}`}
      headerTag={showHeaderTag && <HeaderTag variant={headerTagProps.variant} text={headerTagProps.text} />}
      headerBtn
      headerBtnOptions={{
        isIconTooltip: true,
        className: 'edit-btn',
        variant: 'primary-dark',
        icon: 'pen',
        name: 'Editar Póliza',
        path: `${modelUrl}/${id}/edit`
      }}
      secondBtn={isContract}
      secondBtnOptions={{
        isIconTooltip: true,
        icon: 'software-download',
        name: 'Descargar póliza',
        onClick: () =>
          handleInsurancePolicyRequest({
            xlsxName: `reporte_poliza_${policyNumber}`,
            id
          })
      }}
      thirdBtn={isContract}
      thirdBtnOptions={{
        icon: 'add',
        name: 'Nuevo Endoso',
        path: {
          pathname: `${modelUrl}/${id}/endorsements/${insuranceEndorsementPath}`,
          state: { fromFormHeaderShow: true, modelUrl, originPath: `${modelUrl}/${id}`, insurancePolicy }
        }
      }}
      formComponent={
        <InsurancePolicyInfo
          userModule="admin"
          modelUrl={modelUrl}
          insurancePolicy={insurancePolicy}
          setMoreData={setMoreData}
        />
      }
    />
  );
};

export default InsurancePolicyShow;
