import ApiService from '../services/apiService';

export const exportContractSummaryRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/reports/contract_summary.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportLeadSummaryRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/reports/lead_summary.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportDebtReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/reports/debt_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportAccountReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/reports/account_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};
