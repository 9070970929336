import React from 'react';

import { PeopleSection } from '../../../components/Shared';
import AccountSection from '../../../components/InsurancePolicies/Forms/AccountSection';

const InsurancePolicyFormFirstTab = ({
  allowUpdate,
  setAllowUpdate,
  fromAdmin,
  isProposal,
  modelName,
  fromLeadProposal,
  userModule
}) => {
  return (
    <>
      <AccountSection
        allowUpdate={allowUpdate}
        setAllowUpdate={setAllowUpdate}
        fromAdmin={fromAdmin}
        modelName={modelName}
        isProposal={isProposal}
        fromLeadProposal={fromLeadProposal}
        userModule={userModule}
      />

      <PeopleSection requiredValues fromAdmin={fromAdmin} modelName={modelName} userModule={userModule} />

      {/* <PeopleSection fromAdmin={fromAdmin} modelName={modelName} requiredValues={requiredValues} /> */}
    </>
  );
};

export default InsurancePolicyFormFirstTab;
