import React from 'react';
import { getIn, useFormikContext } from 'formik';
import {
  LicensePlateField,
  NumberField,
  RadioField,
  SelectField,
  TextAreaField,
  TextField
} from './dictionaryFields/index';

const DictionaryFields = ({ modelName, dictionaryDetails = [], action, fromLeadProposal, leadPresent }) => {
  const { values, errors, touched } = useFormikContext();

  const shouldShowField = showif => {
    if (!showif) return true;
    const selectedOption = getIn(values, `${modelName}[${showif?.id}]`);
    return showif?.selectedOption.includes(selectedOption);
  };

  // console.log('errors: ', getIn(errors, `${modelName}`));

  const renderField = (key, details) => {
    const { format, id, showif } = details;

    if (id.includes('other')) {
      if (shouldShowField(showif) && action === 'new' && !fromLeadProposal && !leadPresent) {
        return (
          <ChildField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
          />
        );
      }
      return null;
    }

    switch (format) {
      case 'text':
        return (
          <TextField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
          />
        );
      case 'licenseplate':
        return (
          <LicensePlateField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
          />
        );
      case 'select':
        return (
          <SelectField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
          />
        );
      case 'radio':
        return (
          <RadioField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
          />
        );
      case 'alphanumeric':
        return (
          <NumberField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
          />
        );
      default:
        return null;
    }
  };

  return <>{dictionaryDetails.map((detail, index) => renderField(index, detail))}</>;
};

const ChildField = ({ keyValue, details, modelName, errors, touched }) => {
  const { format } = details;

  switch (format) {
    case 'text':
      return (
        <TextField keyValue={keyValue} details={details} modelName={modelName} errors={errors} touched={touched} />
      );
    case 'select':
      return (
        <SelectField keyValue={keyValue} details={details} modelName={modelName} errors={errors} touched={touched} />
      );
    case 'alphanumeric':
      return (
        <NumberField keyValue={keyValue} details={details} modelName={modelName} errors={errors} touched={touched} />
      );
    case 'textarea':
      return (
        <TextAreaField keyValue={keyValue} details={details} modelName={modelName} errors={errors} touched={touched} />
      );
    case 'radio':
      return (
        <RadioField keyValue={keyValue} details={details} modelName={modelName} errors={errors} touched={touched} />
      );
    default:
      return null;
  }
};
export default DictionaryFields;
