import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import AdminUserForm from './AdminUserForm';
import { createAdminUserRequest } from '../../../requests/admin/adminUsers';
import { FormHeader } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import basicUser from './basicUser';

const AdminUserNew = () => {
  const modelUrl = '/admin/users';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Usuario creado con éxito' }));
    history.push(`/admin/users/`);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = ({ user }) => {
    const formUserParams = { ...user };
    const { roleIds } = formUserParams;

    const isJustAdmin = roleIds.includes(1) && roleIds.length === 1;
    const isJustStandardAdmin = roleIds.includes(6) && roleIds.length === 1;
    const isAdminLanding = roleIds.includes(4);
    if (isJustAdmin || isJustStandardAdmin || isAdminLanding) delete formUserParams.insuranceBrokerAttributes;

    const sendParams = {
      user: snakeCaseKeys(formUserParams)
    };
    createAdminUserRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  const handleBreadcrumb = [
    { key: 1, name: 'Usuarios', href: modelUrl },
    { key: 2, name: 'Crear nuevo usuario' }
  ];

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminUserForm action="new" user={basicUser} formRequest={handleCreateRequest} />}
    />
  );
};

export default AdminUserNew;
