import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import '../../../services/yupCustomMethods';
import { InsuranceBrokerFields } from '../../InsuranceBroker';
import { useFetchData } from '../../../hooks';
import { FormikInput, FormikSelect, FormikSwitch } from '../../../components';
import { debounceIndexRolesRequest } from '../../../requests/roles';
import { handleFormatTextToLowerCase, hasRoleAdmin, isUserBroker, isUserInsured } from '../../../services/utils';
import InsuredFields from './form/InsuredFields';

const AdminUserForm = ({
  action,
  errors,
  onHide,
  submitVariant,
  touched,
  setFieldValue,
  setFieldTouched,
  user,
  values
}) => {
  const isBroker = isUserBroker(getIn(values, 'user'));
  const isInsured = isUserInsured(getIn(values, 'user'));
  const isAdmin = hasRoleAdmin(getIn(values, 'user'));

  const { data: roles } = useFetchData({
    debouncedIndexRequest: debounceIndexRolesRequest,
    fetchingErrorMessage: 'Error al buscar roles. Por favor inténtelo nuevamente.',
    customParams: {
      sort_column: 'name',
      sort_direction: 'asc',
      exclude_role_name: ['insured']
    }
  });

  const handleSelectorIsMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  const submitBtnText = action === 'new' ? 'Crear Usuario' : 'Guardar Cambios';

  return (
    <Form>
      <Row>
        <Col md={isAdmin ? 6 : 12}>
          <Field name="user[active]">
            {({ field }) => <FormikSwitch {...field} field={field} label="¿Usuario activo?" />}
          </Field>
        </Col>
        {isAdmin && (
          <Col md={6} sm={6}>
            <Field name="user[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre y apellido"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
        <Col md={6} sm={12}>
          <Field name="user[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Correo electrónico"
                onChange={e => handleFormatTextToLowerCase(e, setFieldValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="user[roleIds]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                isMulti
                label="Roles"
                placeholder="Seleccionar roles"
                options={roles}
                defaultValue={user.roleIds}
                defaultMultiValues={user.roles}
                onChange={data => handleSelectorIsMulti(data || [], field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                setFieldTouched={() => setFieldTouched(field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        {isInsured && (
          <InsuredFields
            fromAdminUserForm
            values={values}
            errors={errors}
            touched={touched}
            user={user}
            setFieldValue={setFieldValue}
          />
        )}
      </Row>
      <Row>
        {isBroker && (
          <InsuranceBrokerFields
            fromAdminUserForm
            values={values}
            errors={errors}
            touched={touched}
            user={user}
            setFieldValue={setFieldValue}
          />
        )}
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={3} sm={12} className="mt-4">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ user }) => {
  return {
    user
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string()
      .required('Debes ingresar un correo electrónico')
      .email('Debes ingresar un correo electrónico válido'),
    name: Yup.string().when('roleIds', {
      is: roleIds => {
        const adminIdRole = 1;
        const adminLandingIdRole = 4;
        const adminStandardIdRole = 6;
        return (
          roleIds.includes(adminIdRole) || roleIds.includes(adminStandardIdRole) || roleIds.includes(adminLandingIdRole)
        );
      },
      then: Yup.string().required('Debes ingresar nombre y apellido'),
      otherwise: Yup.string().nullable()
    }),
    roleIds: Yup.string().required('Debes seleccionar al menos un rol'),
    insuranceBrokerAttributes: Yup.object().when('roleIds', {
      is: roleIds => {
        const brokerIdRole = 2;
        return roleIds.includes(brokerIdRole);
      },
      then: Yup.object().shape({
        addressAttributes: Yup.object().nullable(),
        brokerCommission: Yup.string()
          .required('Debes ingresar un porcentaje para la comisión')
          .typeError('Debes ingresar un porcentaje para la comisión'),
        companyId: Yup.string().required('Debes ingresar una empresa para el corredor'),
        name: Yup.string().required('Debes ingresar nombre y apellido del corredor'),
        nationalIdentification: Yup.string().required('Debes ingresar RUT del corredor')
      }),
      otherwise: Yup.object().nullable()
    }),
    insuredType: Yup.string().when('roleIds', {
      is: roleIds => {
        const insuredIdRole = 3;
        return roleIds.includes(insuredIdRole);
      },
      then: Yup.string().required('Debes seleccionar un tipo de asegurado'),
      otherwise: Yup.string().nullable()
    })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminUserForm);
