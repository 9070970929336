import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { createAdminAccountRequest } from '../../../requests/admin/adminAccounts';
import { scrollToTop } from '../../../services/utils';
import basicAccount from '../../../screens/Account/basicAccount';
import AccountForm from './AccountForm';
import { createAccountRequest } from '../../../requests/accounts';
import { useFetchData } from '../../../hooks';
import { debounceInsuredContactInfoRequest } from '../../../requests/insured/insuredContacts';
import { createInsuredAccountRequest } from '../../../requests/insured/insuredAccounts';

const AccountModalForm = ({ insuranceBrokerId, handleCancelButton, setRefreshKey, fromAdmin, userModule }) => {
  const [account, setAccount] = useState(basicAccount);
  const dispatch = useDispatch();

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Cuenta creada con éxito' }));
    handleCancelButton();
    setRefreshKey(prevKey => prevKey + 1);
  };

  const getCreateRequest = () => {
    const requestMap = {
      admin: createAdminAccountRequest,
      broker: createAccountRequest,
      insured: createInsuredAccountRequest
    };

    return requestMap[userModule];
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const sendParams = snakeCaseKeys(values);
    const createRequest = getCreateRequest();
    createRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  // const handleCustomParams = () => {
  //   if (insuranceBrokerId && fromAdmin) {
  //     setAccount({ ...account, insuranceBrokerId });
  //   }
  // };

  // const { data: contactInfo } = useFetchData({
  //   debouncedIndexRequest: debounceInsuredContactInfoRequest,
  //   withoutWrapper: true
  // });

  const emptyRequest = () => Promise.resolve();

  const { data: contactInfo } = useFetchData({
    debouncedIndexRequest: userModule === 'insured' ? debounceInsuredContactInfoRequest : emptyRequest,
    withoutWrapper: true
  });

  const handleCustomParams = () => {
    let updatedAccount = { ...account };

    if (insuranceBrokerId && (fromAdmin || userModule === 'insured')) {
      updatedAccount.insuranceBrokerId = insuranceBrokerId;
    }

    if (userModule === 'insured' && contactInfo) {
      const sanitizedContactInfo = {
        addressAttributes: contactInfo.addressAttributes || {
          address: '',
          RegionId: '',
          communeId: ''
        },
        position: contactInfo.position || '',
        phoneNumber: contactInfo.phoneNumber || '',
        email: contactInfo.email || '',
        name: contactInfo.name || '',
        isInsuredUser: contactInfo.isInsuredUser || false,
        isPrimaryContact: contactInfo.isInsuredUser || false
      };

      updatedAccount = {
        ...updatedAccount,
        contactsAttributes: [sanitizedContactInfo]
      };
    }

    setAccount(updatedAccount);
  };

  useEffect(scrollToTop, []);
  useEffect(handleCustomParams, [contactInfo]);

  return (
    <div>
      <AccountForm
        userModule={userModule}
        action="new"
        account={account}
        formRequest={handleCreateRequest}
        handleCancelButton={handleCancelButton}
        fromAdmin={fromAdmin}
      />
    </div>
  );
};

export default AccountModalForm;
