import infoCardElement from '../../Utils/Cards/InfoCardMethods';

const leadObject = ({ lead, setItemsModalShow, userModule }) => {
  const {
    createdAt,
    currency,
    insuranceCategory,
    insuranceItemsAttributes,
    parsedEstimatedNetPrime,
    quotationRequestFileInfo = {},
    subjectMatter,
    validityStart
  } = lead;

  const hasQuotationRequest = Object.keys(quotationRequestFileInfo).length > 0;
  const currentInsuranceItems = insuranceItemsAttributes.filter(insuranceItem => !insuranceItem._destroy);

  const object = {};

  if (hasQuotationRequest)
    object.quotationRequestFile = infoCardElement({
      file: true,
      link: true,
      item: {
        title: 'Documento adjunto',
        name: 'Ver archivo',
        url: quotationRequestFileInfo.fileUrl
      }
    });
  object.insuranceCategoryCategory = infoCardElement({
    string: true,
    key: 'Tipo de seguro',
    value: insuranceCategory.translatedCategory
  });
  object.insuranceCategoryName = infoCardElement({
    string: true,
    key: 'Ramo',
    value: insuranceCategory.name
  });
  object.currency = infoCardElement({
    string: true,
    key: 'Moneda',
    value: currency
  });
  object.createdAt = infoCardElement({
    string: true,
    key: 'Fecha creación oportunidad',
    value: createdAt
  });
  object.validityStart = infoCardElement({
    string: true,
    key: 'Inicio de vigencia',
    value: validityStart
  });
  if (parsedEstimatedNetPrime && (userModule === 'admin' || userModule === 'broker')) {
    object.parsedEstimatedNetPrime = infoCardElement({
      string: true,
      key: 'Prima neta estimada',
      value: parsedEstimatedNetPrime
    });
  }
  object.subjectMatter = infoCardElement({
    string: true,
    key: 'Descripción general',
    value: subjectMatter
  });

  if (currentInsuranceItems.length > 0) {
    object.insuranceItems = infoCardElement({
      file: true,
      item: {
        title: `${currentInsuranceItems.length} ${currentInsuranceItems.length > 1 ? 'Ítems' : 'Ítem'}`,
        name: 'Ver detalle',
        onClick: () => setItemsModalShow(true)
      }
    });
  }

  return object;
};

export default leadObject;
