import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useAbility } from '@casl/react';

import { FormHeader } from '../../../../components';
import { basicLead } from '../../../../components/Lead/basicLead';
import LeadInfo from '../../../../components/Lead/LeadInfo';
import { HeaderTag } from '../../../../components/Shared';
import { AbilityContext } from '../../../../config/abilityContext';
import { useDestroyOne } from '../../../../hooks';
import { deleteAdminLeadRequest, showAdminLeadRequest } from '../../../../requests/admin/lead';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../../../services/utils';

const AdminLeadRenewalShow = ({ match, location }) => {
  const { id } = match.params;
  const modelUrl = '/admin/renewals';
  const showPath = `${modelUrl}/${id}`;

  const [leadRenewal, setLeadRenewal] = useState(basicLead);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [prevFiltersParams, setPrevFiltersParams] = useState(null);

  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    closeStatus,
    hasProposal,
    insurancePolicyContract,
    renewableInsurancePolicy,
    renewableInsurancePolicyValidityEnd
  } = leadRenewal;

  const { id: insurancePolicyId, accountName, policyNumber } = renewableInsurancePolicy;

  const hasContract = insurancePolicyContract.id;
  const contractId = hasContract ? insurancePolicyContract.id : insurancePolicyId;

  const handleBreadcrumb = [
    { key: 1, name: 'Renovaciones', href: modelUrl },
    { key: 2, name: 'Detalle de póliza' }
  ];

  const isStep = value => leadRenewal.step === value;

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteAdminLeadRequest,
    modelName: 'Renovación',
    nameEndsInA: true,
    redirectToUrl: modelUrl,
    infoText:
      isStep('quotation') &&
      'Si no lograste hacer un cierre exitoso, es mejor marcar la renovación como "cierre sin éxito" para mantener el registro.'
  });

  const handleSuccessShow = response => {
    setLeadRenewal(camelCaseEmptyStringRecursive(response.data));
    setOnRequest(false);
  };

  const fetchLead = () => {
    showAdminLeadRequest(id, { dispatch, successCallback: handleSuccessShow });
    setOnRequest(true);
  };

  const handleCreateInsurancePolicy = ({ isRenewal, policyType }) => {
    redirectTo(history, '/admin/insurance_policies/new', {
      policyType,
      isRenewal,
      leadModelUrl: modelUrl,
      leadPath: showPath,
      lead: leadRenewal,
      validityStart: renewableInsurancePolicyValidityEnd
    });
  };

  const canCreatePolicy = () => {
    return isStep('closed') && ['success', 'proposal_generated'].includes(closeStatus);
  };

  const canRequestQuotation = () => {
    return isStep('renewal') && ability.can('manage', 'Lead');
  };

  const handleSetFiltersOnMount = () => {
    const { state } = location;
    if (!state) return;
    setPrevFiltersParams(state.currentParams);
  };

  const thirdBtnAction = () => {
    return {
      icon: 'add',
      name: 'Generar Propuesta',
      onClick: () => handleCreateInsurancePolicy({ isRenewal: true, policyType: 'proposal' })
    };
  };

  useEffect(scrollToTop, []);
  useEffect(handleSetFiltersOnMount, []);
  useEffect(fetchLead, [moreData]);

  return onRequest ? (
    <div className="containerSpinnerLoad" style={{ height: '100%' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      title={`${accountName} - N° ${policyNumber}`}
      headerTag={!leadRenewal.requested && <HeaderTag variant="warning" text="Por solicitar cotización" />}
      headerBtn={isStep('quotation') || canRequestQuotation() || canCreatePolicy()}
      headerBtnOptions={
        (isStep('renewal') && {
          icon: 'add',
          name: 'Solicitar Cotización',
          path: `${showPath}/request_quotation`
        }) ||
        (isStep('quotation') && {
          isIconTooltip: true,
          variant: 'strong-danger',
          icon: 'trash',
          name: 'Eliminar Renovación',
          onClick: () => handleShowModal(leadRenewal)
        }) ||
        (isStep('closed') && {
          icon: 'add',
          name: hasProposal ? 'Crear Póliza' : 'Generar Propuesta',
          onClick: () =>
            handleCreateInsurancePolicy({
              isRenewal: leadRenewal.isRenewal,
              policyType: hasProposal ? 'contract' : 'proposal'
            })
        })
      }
      secondBtn
      secondBtnOptions={{
        icon: 'notes',
        iconSize: 'sm',
        name: 'Póliza',
        onClick: () => redirectTo(history, `/admin/insurance_policies/${contractId}`)
      }}
      thirdBtn={isStep('renewal')}
      thirdBtnOptions={thirdBtnAction()}
      previousFilterParams={prevFiltersParams}
      formComponent={
        <LeadInfo renewal userModule="admin" modelUrl={modelUrl} lead={leadRenewal} setMoreData={setMoreData} />
      }
    >
      <OnDestroyModalComponent />
    </FormHeader>
  );
};

export default AdminLeadRenewalShow;
