const initialFilterParams = {
  query: '',
  dateTo: '',
  dateFrom: '',
  filterTableTabs: 'open',
  filter_collection_type: 'premium'
};

const collectionTypeOptions = [
  { label: 'Prima', value: 'premium' },
  { label: 'Comisión', value: 'commission' }
];

const createdFromOptions = [
  // { label: 'Creación cadencia única', value: 'unique_cadence' }, // Commented by card #1018
  { label: 'Deuda cadencia única', value: 'debt' },
  { label: 'Cadencia mensual', value: 'monthly_cadence' }
  // { label: 'Endoso cadencia única', value: 'endorsement' } // Commented by card #1018
];

const createdFormOptions = [
  { label: 'Creación cadencia única', value: 'unique_cadence' },
  { label: 'Deuda cadencia única', value: 'debt' },
  { label: 'Cadencia mensual', value: 'monthly_cadence' },
  { label: 'Endoso cadencia única', value: 'endorsement' }
];

const statusOptions = [
  { label: 'Pendiente cobro compañía', value: 'pending_collection' },
  { label: 'Pendiente pago cliente', value: 'pending_payment' },
  { label: 'Pagada', value: 'closed' }
];

const tableTabOptions = [
  { label: 'Abiertas', value: 'open' },
  { label: 'Pagadas', value: 'closed' },
  { label: 'Todas', value: 'all' }
];

export {
  collectionTypeOptions,
  createdFormOptions,
  createdFromOptions,
  initialFilterParams,
  statusOptions,
  tableTabOptions
};
