import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useAbility } from '@casl/react';

import { FormHeader } from '../../../components';
import { basicLead } from '../../../components/Lead/basicLead';
import LeadInfo from '../../../components/Lead/LeadInfo';
import { HeaderTag } from '../../../components/Shared';
import { AbilityContext } from '../../../config/abilityContext';
import { useDestroyOne } from '../../../hooks';
import { deleteLeadRequest, showLeadRequest } from '../../../requests/lead';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../../services/utils';

const LeadRenewalShow = ({ match, location }) => {
  const { id } = match.params;
  const modelUrl = '/renewals';

  const [isFetching, setIsFetching] = useState(true);
  const [leadRenewal, setLeadRenewal] = useState(basicLead);
  const [moreData, setMoreData] = useState(false);
  const [prevFiltersParams, setPrevFiltersParams] = useState(null);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { insurancePolicyContract, renewableInsurancePolicy } = leadRenewal;
  const { id: insurancePolicyId, accountName, policyNumber } = renewableInsurancePolicy;

  const hasContract = insurancePolicyContract.id;
  const contractId = hasContract ? insurancePolicyContract.id : insurancePolicyId;

  const handleBreadcrumb = [
    { key: 1, name: 'Renovaciones', href: modelUrl },
    { key: 2, name: 'Detalle de póliza' }
  ];

  const isStep = value => leadRenewal.step === value;

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteLeadRequest,
    modelName: 'Renovación',
    nameEndsInA: true,
    redirectToUrl: modelUrl,
    infoText:
      isStep('quotation') &&
      'Si no lograste hacer un cierre exitoso, es mejor marcar la renovación como "cierre sin éxito" para mantener el registro.'
  });

  const canRequestQuotation = () => {
    return isStep('renewal') && ability.can('manage', 'Lead');
  };

  const handleSetFiltersOnMount = () => {
    const { state } = location;
    if (!state) return;
    setPrevFiltersParams(state.currentParams);
  };

  const handleSuccessShow = response => {
    setLeadRenewal(camelCaseEmptyStringRecursive(response.data));
    setIsFetching(false);
  };

  const fetchLead = () => {
    showLeadRequest(id, { dispatch, successCallback: handleSuccessShow });
    setIsFetching(true);
  };

  useEffect(scrollToTop, []);
  useEffect(fetchLead, [moreData]);
  useEffect(handleSetFiltersOnMount, []);

  return (
    <>
      {isFetching && (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!isFetching && (
        <FormHeader
          handleBreadcrumb={handleBreadcrumb}
          title={`${accountName} - N° ${policyNumber}`}
          headerTag={!leadRenewal.requested && <HeaderTag variant="warning" text="Por solicitar cotización" />}
          headerBtn={isStep('quotation') || canRequestQuotation()}
          headerBtnOptions={
            isStep('quotation')
              ? {
                  isIconTooltip: true,
                  variant: 'strong-danger',
                  icon: 'trash',
                  name: 'Eliminar Renovación',
                  onClick: () => handleShowModal(leadRenewal)
                }
              : {
                  icon: 'add',
                  name: 'Solicitar Cotización',
                  path: `${modelUrl}/${id}/request_quotation`
                }
          }
          secondBtn
          secondBtnOptions={{
            icon: 'notes',
            iconSize: 'sm',
            name: 'Póliza',
            onClick: () => redirectTo(history, `/insurance_policies/${contractId}`)
          }}
          previousFilterParams={prevFiltersParams}
          formComponent={
            <LeadInfo renewal userModule="broker" modelUrl={modelUrl} lead={leadRenewal} setMoreData={setMoreData} />
          }
        />
      )}
      <OnDestroyModalComponent />
    </>
  );
};

export default LeadRenewalShow;
