import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { showAccountRequest } from '../../requests/accounts';
import { showAdminAccountRequest } from '../../requests/admin/adminAccounts';
import { rutFormat, validRutInput, camelCaseEmptyStringRecursive } from '../../services/utils';
import { BtnTooltip } from '../Utils/Button';
import { FormikInput } from '../Utils/Input';
import { ButtonTooltip } from '../Utils/Tooltips';
import { showInsuredAccountRequest } from '../../requests/insured/insuredAccounts';

const PeopleSection = ({ modelName, userModule }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const modelValues = values[`${modelName}`];
  const dispatch = useDispatch();

  const { accountId } = modelValues;

  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  const handleSetPersonInformation = (fieldName, name, nationalIdentification, address) => {
    setFieldValue(`${modelName}[${fieldName}Name]`, name);
    setFieldValue(`${modelName}[${fieldName}NationalIdentification]`, nationalIdentification);
    setFieldValue(`${modelName}[${fieldName}Address]`, address);
  };

  const handleSuccessAccountRequest = (response, fieldName) => {
    const account = camelCaseEmptyStringRecursive(response.data);
    const { name, nationalIdentification, primaryContactAddress } = account;
    handleSetPersonInformation(fieldName, name, nationalIdentification, primaryContactAddress);
  };

  const getShowRequest = () => {
    const requestMap = {
      admin: showAdminAccountRequest,
      broker: showAccountRequest,
      insured: showInsuredAccountRequest
    };
    return requestMap[userModule];
  };

  const handleCopyAccountInformation = fieldName => {
    if (accountId) {
      const showRequest = getShowRequest();
      showRequest(accountId, {
        dispatch,
        successCallback: response => handleSuccessAccountRequest(response, fieldName)
      });
    }
  };

  const hiringPersonTooltip = () => {
    return `El Contratante es la persona natural o jurídica que asume la responsabilidad<br/>
    sobre la Póliza (contratación, pago de primas, modificaciones, etc.).`;
  };

  const insuredPersonTooltip = () => {
    return `El Asegurado es la persona natural o jurídica cuyo riesgo es protegido por la Póliza.`;
  };

  const beneficiaryPersonTooltip = () => {
    return `El Beneficiario es la persona natural o jurídica que es compensada<br/>
    si el asegurado sufre un siniestro cubierto por la Póliza.`;
  };

  return (
    <section className="form-section mb-5 w-100">
      <p className="section-title">Información de las partes</p>

      <div className="d-flex align-items-center">
        <p className="section-title d-flex align-items-center mb-0">Contratante</p>
        <ButtonTooltip tooltipText={hiringPersonTooltip()} className="mx-3" direction="right">
          ?
        </ButtonTooltip>
      </div>

      <Row className="mb-5">
        <Col>
          <Field name={`${modelName}[hiringPersonName]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="Nombre y Apellido"
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[hiringPersonNationalIdentification]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="RUT"
                label="RUT"
                onChange={handleNationalIdentificationFormat}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[hiringPersonAddress]`}>
            {({ field }) => (
              <FormikInput
                abbr
                {...field}
                placeholder="Dirección, comuna y ciudad"
                label="Dirección"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col lg={2} className="d-flex justify-content-center align-items-center">
          <BtnTooltip
            block
            iconSize="sm"
            tooltipText="Autocompletar con información de la cuenta"
            onClick={() => handleCopyAccountInformation('hiringPerson')}
          >
            Autocompletar
          </BtnTooltip>
        </Col>
      </Row>

      <div className="d-flex align-items-center">
        <p className="section-title d-flex align-items-center mb-0">Asegurado</p>
        <ButtonTooltip tooltipText={insuredPersonTooltip()} className="mx-3" direction="right">
          ?
        </ButtonTooltip>
      </div>
      <Row className="mb-5">
        <Col>
          <Field name={`${modelName}[insuredPersonName]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="Nombre y apellido"
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[insuredPersonNationalIdentification]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="RUT"
                label="RUT"
                onChange={handleNationalIdentificationFormat}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[insuredPersonAddress]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="Dirección, comuna y ciudad"
                label="Dirección"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col lg={2} className="d-flex justify-content-center align-items-center">
          <BtnTooltip
            block
            iconSize="sm"
            tooltipText="Autocompletar con información de la cuenta"
            onClick={() => handleCopyAccountInformation('insuredPerson')}
          >
            Autocompletar
          </BtnTooltip>
        </Col>
      </Row>

      <div className="d-flex align-items-center">
        <p className="section-title d-flex align-items-center mb-0">
          Beneficiario <span className="text-color-grey ml-2">(Opcional)</span>
        </p>
        <ButtonTooltip tooltipText={beneficiaryPersonTooltip()} className="mx-3" direction="right">
          ?
        </ButtonTooltip>
      </div>
      <Row className="mb-5">
        <Col>
          <Field name={`${modelName}[beneficiaryPersonName]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                placeholder="Nombre y Apellido"
                label="Nombre y apellido"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[beneficiaryPersonNationalIdentification]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                placeholder="RUT"
                label="RUT"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[beneficiaryPersonAddress]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                placeholder="Dirección, comuna y ciudad"
                label="Dirección"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col lg={2} className="d-flex justify-content-center align-items-center">
          <BtnTooltip
            block
            iconSize="sm"
            tooltipText="Autocompletar con información de la cuenta"
            onClick={() => handleCopyAccountInformation('beneficiaryPerson')}
          >
            Autocompletar
          </BtnTooltip>
        </Col>
      </Row>
    </section>
  );
};

export default PeopleSection;
