import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useAbility } from '@casl/react';

import { FormHeader } from '../../components';
import { basicLead } from '../../components/Lead/basicLead';
import LeadInfo from '../../components/Lead/LeadInfo';
import { HeaderTag } from '../../components/Shared';
import { AbilityContext } from '../../config/abilityContext';
import { useDestroyOne } from '../../hooks';
import { deleteLeadRequest, showLeadRequest } from '../../requests/lead';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../services/utils';

const LeadShow = ({ match, location }) => {
  const { id } = match.params;
  const modelUrl = '/leads';

  const [isFetching, setIsFetching] = useState(true);
  const [lead, setLead] = useState(basicLead);
  const [moreData, setMoreData] = useState(false);
  const [prevFiltersParams, setPrevFiltersParams] = useState(null);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { insurancePolicyContract } = lead;
  const hasContract = insurancePolicyContract.id;

  const handleBreadcrumb = [
    { key: 1, name: 'Ventas', href: modelUrl },
    { key: 2, name: 'Detalle de oportunidad' }
  ];

  const isStep = value => lead.step === value;

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteLeadRequest,
    modelName: 'Oportunidad',
    nameEndsInA: true,
    redirectToUrl: modelUrl,
    infoText:
      isStep('quotation') &&
      'Si no lograste hacer un cierre exitoso, es mejor marcar la oportunidad como "cierre sin éxito" para mantener el registro.'
  });

  const handleSuccessShow = response => {
    setLead(camelCaseEmptyStringRecursive(response.data));
    setIsFetching(false);
  };

  const handleShowAccount = () => {
    showLeadRequest(id, {
      dispatch,
      successCallback: handleSuccessShow
    });
    setIsFetching(true);
  };

  const handleSetFiltersOnMount = () => {
    const { state } = location;
    if (!state) return;
    setPrevFiltersParams(state.currentParams);
  };

  useEffect(handleSetFiltersOnMount, []);
  useEffect(scrollToTop, []);
  useEffect(handleShowAccount, [moreData]);

  return (
    <>
      {isFetching ? (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <FormHeader
          handleBreadcrumb={handleBreadcrumb}
          title={lead?.account?.name}
          headerTag={!lead.requested && <HeaderTag variant="warning" text="Por solicitar cotización" />}
          headerBtn={!isStep('closed')}
          headerBtnOptions={{
            isIconTooltip: true,
            variant: 'strong-danger',
            icon: 'trash',
            name: 'Eliminar Oportunidad',
            onClick: () => handleShowModal(lead)
          }}
          secondBtn
          secondBtnOptions={{
            isIconTooltip: true,
            className: 'edit-btn',
            variant: 'primary-dark',
            icon: 'pen',
            name: 'Editar',
            path: `${modelUrl}/${id}/edit`
          }}
          thirdBtn={(isStep('oportunity') && ability.can('manage', 'Lead')) || hasContract}
          thirdBtnOptions={
            hasContract
              ? {
                  icon: 'notes',
                  iconSize: 'sm',
                  name: 'Póliza',
                  onClick: () => redirectTo(history, `/insurance_policies/${insurancePolicyContract.id}`)
                }
              : {
                  icon: 'add',
                  name: 'Solicitar Cotización',
                  path: `${modelUrl}/${id}/request_quotation`
                }
          }
          previousFilterParams={prevFiltersParams}
          formComponent={<LeadInfo userModule="broker" modelUrl={modelUrl} lead={lead} setMoreData={setMoreData} />}
        />
      )}
      <OnDestroyModalComponent />
    </>
  );
};

export default LeadShow;
