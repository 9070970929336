import camelCaseRecursive from 'camelcase-keys-recursive';
import { formatISO } from 'date-fns';
import moment from 'moment';

const responseToBlob = response => {
  const format = response.headers['content-type'].split('/')[1];
  let blob = '';
  const { data } = response;
  if (format === 'xlsx') {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i !== byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  } else if (format === 'csv') {
    const readUTF8 = data.toString('utf8');
    blob = new Blob([readUTF8], { type: 'application/vnd.ms-excel' });
  } else {
    blob = new Blob([data], { type: response.headers['content-type'] });
  }
  return blob;
};

const parseFilename = headers => {
  let filename = '';
  const disposition = headers['content-disposition'];
  if (disposition && (disposition.indexOf('attachment') !== -1 || disposition.indexOf('inline') !== -1)) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

const downloadFile = response => {
  const blob = responseToBlob(response);
  const filename = parseFilename(response.headers);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${filename}`;
  link.click();
  window.URL.revokeObjectURL(url);
};

const openFile = file => {
  const blob = new Blob([file], { type: file.type });
  let url = URL.createObjectURL(blob);
  if (file.id) url = file.fileUrl;
  window.open(url, '_blank', 'noopener');
};

const insertString = (value, position, str) => {
  return value.slice(0, position) + str + value.slice(position);
};

const validPhoneInput = value => {
  const re = /^(\+?56)[0-9](\s?)[0-9]\d{7}$/;
  return value === '' || value === '+56' || re.test(value);
};

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

const capitalizeString = str => {
  if (!str) return '';
  const lower = str.toLowerCase();
  return capitalizeFirstLetter(lower);
};

const rutFormat = value => {
  let rut = value
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  if (rut.length > 1) {
    rut = insertString(rut, -1, '-');
  }
  if (rut.length > 5) {
    rut = insertString(rut, -5, '.');
  }
  if (rut.length > 9) {
    rut = insertString(rut, -9, '.');
  }
  return rut;
};

const textUpperCase = text => text.toUpperCase();

const lowerCaseTextFormat = text => {
  if (text) return text.toLowerCase();
  return '';
};

const alphaNumericCapitalFormat = value => {
  const re = /^[0-9a-zA-Z]{0,}$/;
  if (re.test(value)) {
    return value.toUpperCase().trim();
  }
  return value
    .substr(0, value.length - 1)
    .toUpperCase()
    .trim();
};

const validRutInput = e => {
  const re = /^[0-9kK\b]+$/;
  const rawRut = e.target.value
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  return e.target.value === '' || re.test(rawRut);
};

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(?=.{1,64}@)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const licensePlateRegex = new RegExp('^[a-zA-Z]{4}[0-9]{2}$');

const rutValidation = rutInput => {
  // FORMAT
  const rut = rutInput
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  const body = rut.slice(0, -1);
  let dv = rut.slice(-1).toUpperCase();
  // Compute
  let sum = 0;
  let multiple = 2;
  // For every digit
  for (let i = 1; i <= body.length; i += 1) {
    // Get product
    const index = multiple * rut.charAt(body.length - i);
    // add to count
    sum += index;
    // In range [2,7]
    if (multiple < 7) {
      multiple += 1;
    } else {
      multiple = 2;
    }
  }
  // Division Remainder
  const dvComputed = 11 - (sum % 11);
  // (0 & K)
  dv = dv === 'K' ? 10 : dv;
  dv = dv === '0' ? 11 : dv;
  // Is valid?
  if (`${dvComputed}` !== `${dv}`) {
    return false;
  }
  return true;
};

const isValidRut = rut => {
  const result =
    (/^\d{1,2}\.?\d{3}\.?\d{3}[-]?[0-9kK]{1} *$/i.test(rut) || /^\d{1,2}\.\d{3}\.\d{3}[-]?[0-9kK]{1} *$/i.test(rut)) &&
    rutValidation(rut);
  return result;
};

const emptyStringRecursive = obj => {
  if (typeof obj !== 'object') {
    return obj;
  }
  const newObj = obj;
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(subObj => emptyStringRecursive(subObj));
    } else if (value === null) {
      newObj[key] = '';
    }
  });
  return newObj;
};

const isObject = v => typeof v === 'object';

const camelCase = str => str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());

const camelCaseEmptyStringRecursive = obj => {
  if (obj === null) return '';
  if (obj instanceof Date) return obj;
  if (Array.isArray(obj)) return obj.map(value => camelCaseEmptyStringRecursive(value));
  if (isObject(obj)) {
    const newObject = {};
    Object.entries(obj).forEach(([key, value]) => {
      newObject[camelCase(key)] = camelCaseEmptyStringRecursive(value);
    });
    return newObject;
  }
  return obj;
};

const unitSanitazer = obj => {
  const newObj = obj;
  if (typeof newObj.translated_unit === 'object') {
    newObj.translated_unit = '';
  }
  return newObj;
};

const isMobile = () => {
  let check = false;
  (a => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const groupBy = (array, attribute, optionalAttribute = null) => {
  return array.reduce((acc, current) => {
    const { [`${attribute}`]: key, [`${optionalAttribute}`]: optionalKey } = current;
    const vKey = key || optionalKey;
    if (acc.includes(acc.find(obj => Object.keys(obj).includes(vKey)))) {
      acc.find(obj => Object.keys(obj).includes(vKey) && obj[`${vKey}`].push(current));
    } else {
      acc.push({ [vKey]: [current] });
    }
    return acc;
  }, []);
};

const formatCurrency = ({ addon, leftAddon, value, decimals }) => {
  if (typeof value === 'string') return value;
  const currentDecimals = decimals === undefined ? 2 : decimals;
  const [currency, decimal] = parseFloat(value || 0)
    .toFixed(currentDecimals)
    .split('.');
  let formattedValue = `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
  if (currentDecimals) formattedValue = `${formattedValue},${decimal}`;
  if (leftAddon) return `${leftAddon}${formattedValue}`;
  return `${formattedValue}${addon}`;
};

const toInteger = number => parseInt(number, 0);

const shortText = (string, maxLenght) => {
  return string.length > maxLenght ? `${string.substring(0, maxLenght)}...` : string;
};

const numberRange = (start, end) => new Array(end - start + 1).fill().map((d, i) => i + start);

const sumArrayValues = array => array.reduce((a, b) => a + b, 0);

const parseQuery = queryString => {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

const getUserRoles = user => {
  const { roleIds: userRoles, roles } = camelCaseRecursive(user);
  if (!userRoles && !roles) return [];
  if (!userRoles?.length && !roles?.length) return [];
  return userRoles || roles;
};

const isUserAdmin = user => {
  const userRoles = getUserRoles(user);
  const adminRole = 1;

  return userRoles.includes(adminRole);
};

const isUserAdminLanding = user => {
  const userRoles = getUserRoles(user);
  const adminRole = 4;

  return userRoles.includes(adminRole) || userRoles.includes('admin_landing');
};

const isUserAdminStandard = user => {
  const userRoles = getUserRoles(user);
  const adminRole = 6;

  return userRoles.includes(adminRole) || userRoles.includes('admin_standard');
};

const isUserJustAdmin = user => {
  const userRoles = getUserRoles(user);
  const adminRole = 1;

  return (userRoles.includes(adminRole) || userRoles.includes('admin')) && userRoles.length === 1;
};

const isSingleAdmin = user => {
  const adminRoles = ['admin', 'admin_standard', 'admin_landing'];
  const adminRolesIds = [1, 4, 6];
  const userRoles = getUserRoles(user);
  return (
    userRoles.length === 1 &&
    (adminRolesIds.some(roleId => userRoles.includes(roleId)) ||
      adminRoles.some(roleName => userRoles.includes(roleName)))
  );
};

const hasRoleAdmin = user => {
  const adminRoles = ['admin', 'admin_standard', 'admin_landing'];
  const adminRolesIds = [1, 4, 6];
  const userRoles = getUserRoles(user);
  return (
    adminRolesIds.some(roleId => userRoles.includes(roleId)) ||
    adminRoles.some(roleName => userRoles.includes(roleName))
  );
};

const isUserBroker = user => {
  const userRoles = getUserRoles(user);
  const brokerRole = 2;

  return userRoles.includes(brokerRole);
};

const isUserInsured = user => {
  const userRoles = getUserRoles(user);
  const insuredRole = 3;

  return userRoles.includes(insuredRole);
};

const emptyFalseParamsRecursive = (params = {}) => {
  return Object.entries(params)
    .map(([key, value]) => [key, value === 0 ? value : value || ''])
    .reduce((acc, el) => {
      const [key, value] = el;
      return { ...acc, [key]: value };
    }, {});
};

const scrollToTop = () => {
  const body = document.querySelector('body');
  body.scrollIntoView({ behavior: 'smooth' }, 2000);
};

const guidGenerator = () => {
  const S4 = () => {
    // eslint-disable-next-line no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

const combineDateTime = (inputDate, inputTime) => {
  const justDate = formatISO(new Date(inputDate), { representation: 'date' });
  const timeToParse = inputTime || new Date(new Date().setHours(0, 0, 0, 0));
  const justTime = formatISO(new Date(timeToParse), { representation: 'time' });
  const stringDate = `${justDate}T${justTime}`;
  return new Date(stringDate);
};

const sortResultLast = (a, b, result) => {
  if (a.label === b.label) return 0;
  if (a.label === result) return 1;
  if (b.label === result) return -1;

  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
};

const redirectTo = (history, to, params = {}) => history.push(to, params);

const percentageLimit = ({ value }) => value >= 0 && value <= 100;

const partPerThousandLimit = ({ value }) => value >= 0 && value <= 9.999;

const calculateDays = (startDate, endDate) => {
  if (!startDate || !endDate) return 0;
  const formattedStartDate = moment(startDate, 'DD/MM/YYYY');
  const formattedEndDate = moment(endDate, 'DD/MM/YYYY');
  return formattedEndDate.diff(formattedStartDate, 'days');
};

const handleDateFormat = date => moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');

const addDaysToDate = (date, days) => {
  if (!date) return date;
  const customDate = new Date(date);
  customDate.setDate(customDate.getDate() + days);
  return moment(customDate).format('DD/MM/YYYY');
};

const addOneYear = date => {
  if (!date) return date;
  const customDate = new Date(date);
  customDate.setFullYear(customDate.getFullYear() + 1);
  return moment(customDate).format('DD/MM/YYYY');
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const stringCamelCaseToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const excludeKeys = (object, keys = []) => {
  const copyObject = Object.assign({}, object);
  keys.forEach(key => delete copyObject[key]);
  return copyObject;
};

const emptyKeys = (object, keys = []) => {
  const copyObject = Object.assign({}, object);
  keys.forEach(key => {
    copyObject[key] = '';
  });
  return copyObject;
};

const recursiveArrayObjectEmptyKeys = (array, keys = []) => {
  const duplicateArray = [];
  array.forEach(element => {
    if (typeof element === 'object' && element !== null) {
      const cleanObject = emptyKeys(element, keys);
      const objectKeys = Object.keys(cleanObject);
      objectKeys.forEach(key => {
        if (Array.isArray(cleanObject[key])) {
          cleanObject[key] = recursiveArrayObjectEmptyKeys(cleanObject[key], keys);
        }
      });
      duplicateArray.push(cleanObject);
    }
  });
  return duplicateArray;
};

const extractKeys = (object, extract = [], deleteKeys = [], excludeEmptyKeys = false) => {
  let newObject = {};
  if (Object.keys(object).length === 0) return newObject;
  extract.forEach(key => {
    const value = object[key] || object[key] === 0 ? object[key] : '';
    const addKey = { [key]: value };

    if (excludeEmptyKeys && !addKey[key] && addKey[key] !== 0) return;
    Object.assign(newObject, addKey);
  });

  if (deleteKeys.length > 0) newObject = excludeKeys(newObject, deleteKeys);
  return newObject;
};

const hasActiveValues = object => {
  const activeValues = Object.values(object).filter(item => item === true);
  return activeValues.length > 0;
};

const handleFormatTextToLowerCase = (e, setFieldValue) => {
  const formattedValue = lowerCaseTextFormat(e.target.value);
  setFieldValue(e.target.name, formattedValue);
};

const setFieldAttribute = (modelName, attribute, setFieldValue, value, addon, decimals = 2) => {
  setFieldValue(`${modelName}[${attribute}]`, value);
  setFieldValue(`${modelName}[parsed${capitalizeFirstLetter(attribute)}]`, formatCurrency({ value, addon, decimals }));
};

const isEmptyAttribute = attribute => !attribute?.toString();

export {
  addDaysToDate,
  addOneYear,
  alphaNumericCapitalFormat,
  calculateDays,
  camelCase,
  camelCaseEmptyStringRecursive,
  capitalizeFirstLetter,
  capitalizeString,
  combineDateTime,
  downloadFile,
  emailRegex,
  emptyFalseParamsRecursive,
  emptyKeys,
  emptyStringRecursive,
  excludeKeys,
  extractKeys,
  formatBytes,
  formatCurrency,
  licensePlateRegex,
  groupBy,
  guidGenerator,
  handleDateFormat,
  handleFormatTextToLowerCase,
  hasActiveValues,
  hasRoleAdmin,
  isEmptyAttribute,
  isMobile,
  isObject,
  isSingleAdmin,
  isUserAdmin,
  isUserAdminStandard,
  isUserAdminLanding,
  isUserBroker,
  isUserInsured,
  isUserJustAdmin,
  isValidRut,
  lowerCaseTextFormat,
  numberRange,
  openFile,
  parseQuery,
  partPerThousandLimit,
  percentageLimit,
  recursiveArrayObjectEmptyKeys,
  redirectTo,
  rutFormat,
  scrollToTop,
  setFieldAttribute,
  shortText,
  sortResultLast,
  stringCamelCaseToSnakeCase,
  sumArrayValues,
  textUpperCase,
  toInteger,
  unitSanitazer,
  validPhoneInput,
  validRutInput
};
