import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminPremiumCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/premium_collections', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createAdminPremiumCollectionRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/premium_collections', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateAdminPremiumCollectionRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/admin/premium_collections/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteAdminPremiumCollectionRequest = (
  id,
  { dispatch, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('delete', `/admin/premium_collections/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const exportAdminPremiumCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/premium_collections/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const headerDashboardAdminPremiumCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/premium_collections/header_dashboard', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const buildRecordAdminPremiumCollectionRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/premium_collections/build_record', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const adminBuklUploadCommissionRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('post', `/admin/premium_collections/bulk_upload`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const exportAdminCommissionCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/premium_collections/export_commissions.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const sendPaymentReminderRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/premium_collections/send_payment_reminder', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexAdminPremiumCollectionRequest = AwesomeDebouncePromise(
  indexAdminPremiumCollectionRequest,
  300
);

export const debounceUpdateAdminPremiumCollectionRequest = AwesomeDebouncePromise(
  updateAdminPremiumCollectionRequest,
  500
);

export const debounceHeaderDashboardAdminPremiumCollectionRequest = AwesomeDebouncePromise(
  headerDashboardAdminPremiumCollectionRequest,
  300
);
