import React, { useState } from 'react';

import { Button } from 'react-bootstrap';
import { AutoSaveCommentsForm, SimpleCenteredModal } from '..';
import InfoCardLeadQuotation from '../LeadQuotation/InfoCard/InfoCardLeadQuotation';
import { InfoCardAdditionalDocument } from '../Shared';
import InfoCardLead from './InfoCard/InfoCardLead';
import basicInfoRoles from './basicInfoRoles';
import RollbackCloseStatusModal from './RollbackCloseStatusModal';
import InfoCardGeneral from './InfoCard/InfoCardGeneral';
import InfoCardCoverage from './InfoCard/InfoCardCoverage';

const LeadInfo = ({ lead = {}, modelUrl, renewal = false, setMoreData, userModule }) => {
  const { id, additionalDocumentsInfo, adminComments, closeStatus, step } = lead;

  // GET CURRENT ROLE DATA
  const currentUserOptions = basicInfoRoles[userModule];
  const { can, debounceUpdateRequest, isAdmin, leadQuotationMessage, updateRequest } = currentUserOptions;
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');

  const isStep = value => step === value;

  const policyOnCreationMessage = () => {
    if (isAdmin) return false;
    return isStep('closed') && ['success', 'proposal_generated'].includes(closeStatus);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleBackToStep2 = () => {
    setModalShow(true);
    setModalBody(
      <RollbackCloseStatusModal
        handleModalClose={handleModalClose}
        isAdmin={isAdmin}
        leadId={lead.id}
        setMoreData={setMoreData}
      />
    );
  };

  const showBtnBackToStep2 = () => {
    return isStep('closed') && ['success', 'proposal_generated', 'failure'].includes(closeStatus);
  };

  return (
    <>
      {isStep('quotation') && (
        <InfoCardLeadQuotation
          fromAdmin={isAdmin}
          modelUrl={modelUrl}
          lead={lead}
          setMoreData={setMoreData}
          leadQuotationMessage={leadQuotationMessage}
          canCloseQuotation={can.closeQuotation}
          isRenewal={renewal}
        />
      )}

      <div className="lead-show">
        <div className="account-show--general-info">
          <InfoCardGeneral userModule={userModule} lead={lead} />

          {!renewal && (
            <InfoCardAdditionalDocument
              modelName="lead"
              object={lead}
              additionalDocuments={additionalDocumentsInfo}
              setMoreData={setMoreData}
              updateRequest={updateRequest}
              canAddDocuments={can.addAdditionalDocument}
            />
          )}
        </div>
        <div className="lead-show__second-column">
          <InfoCardLead
            userModule={userModule}
            lead={lead}
            modelUrl={modelUrl}
            fromAdmin={isAdmin}
            setMoreData={setMoreData}
          />

          {isStep('closed') && (
            <InfoCardCoverage
              userModule={userModule}
              lead={lead}
              modelUrl={modelUrl}
              fromAdmin={isAdmin}
              setMoreData={setMoreData}
            />
          )}

          {can.autoSaveComment && (
            <AutoSaveCommentsForm
              withTitle
              resourceId={id.toString()}
              resourceComments={lead.comments}
              resourcePath="lead[comments]"
              debouncedUpdateRequest={debounceUpdateRequest}
              tooltipText="La información ingresada en esta sección es visible para NICO y Corredores asociados."
            />
          )}
        </div>
      </div>

      {can.autoSaveComment && isAdmin && (
        <div className="mt-5">
          <AutoSaveCommentsForm
            withTitle
            title="Nota admin"
            resourceId={id.toString()}
            resourceComments={adminComments}
            resourcePath="lead[admin_comments]"
            debouncedUpdateRequest={debounceUpdateRequest}
            tooltipText="La información ingresada en esta sección es visible solo para NICO."
          />
        </div>
      )}

      {policyOnCreationMessage() && (
        <section className="lead-footer--message text-center mt-5">
          <p className="font-weight-bold">
            <abbr className="text-danger">*</abbr> La póliza está en proceso de creación a través de nico, si tienes
            alguna duda <span className="text-primary">comunícate con nosotros</span>
          </p>
        </section>
      )}

      {showBtnBackToStep2() && (
        <div className="d-flex justify-content-end mt-5">
          <Button className="btn-sm outline" variant="danger" onClick={() => handleBackToStep2()}>
            Volver a etapa 2
          </Button>
        </div>
      )}

      <SimpleCenteredModal show={modalShow} body={modalBody} onHide={handleModalClose} />
    </>
  );
};

export default LeadInfo;
