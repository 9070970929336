import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import moment from 'moment';
import { useAbility } from '@casl/react';

import { DatesFilter, StatisticCard } from '../../components';
import {
  exportAccountReportRequest,
  exportContractSummaryRequest,
  exportDebtReportRequest,
  exportLeadSummaryRequest
} from '../../requests/reports';
import { downloadFile } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';

const ReportIndex = () => {
  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);

  const beginningOfMonth = new Date(moment().startOf('month'));
  const endOfMonth = new Date(moment().endOf('month'));

  const [customParams, setCustomParams] = useState({ exportDateFrom: beginningOfMonth, exportDateTo: endOfMonth });

  const handleDateRangeValues = ({ ignoreDates = false, filterName, xlsxName, ...extraProps }) => {
    if (ignoreDates) return { ignoreDates, filterName, xlsxName, ...extraProps };

    const { exportDateFrom, exportDateTo } = customParams;
    const name = `export_${filterName}`;

    return { filterName, xlsxName, [`${name}_from`]: exportDateFrom, [`${name}_to`]: exportDateTo, ...extraProps };
  };

  const handleContractSummaryRequest = ({ filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ filterName, xlsxName, ...extraProps });
    exportContractSummaryRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleLeadSummaryRequest = ({ filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ filterName, xlsxName, ...extraProps });
    exportLeadSummaryRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleDebtReportRequest = ({ filterName, xlsxName, ...extraProps }) => {
    const sendParams = { xlsxName, ...extraProps };
    exportDebtReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleAccountReportRequest = ({ filterName, xlsxName, ...extraProps }) => {
    const sendParams = { xlsxName, ...extraProps };
    exportAccountReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  return (
    <Row className="mb-5">
      <Col lg={7} className="mt-4 pt-2">
        <h5 className="text-primary-dark-blue">Reportes</h5>
      </Col>
      <Col lg={5} className="d-lg-flex justify-content-lg-end pt-4">
        <DatesFilter
          startDateField="exportDateFrom"
          endDateField="exportDateTo"
          customParams={customParams}
          setCustomParams={setCustomParams}
        />
      </Col>

      {ability.can('manage', 'PolicyReport') && (
        <>
          <Col sm={6} lg={4} className="mt-5">
            <StatisticCard
              icon="document-type-thin"
              variant="primary-light"
              title="Pólizas"
              subtitle="(fecha de creación)"
              clickable
              onClick={() =>
                handleContractSummaryRequest({
                  filterName: 'created_at',
                  xlsxName: 'reporte_de_polizas_por_creacion',
                  filterSelect: ['policy', 'created_at']
                })
              }
            />
          </Col>
          <Col sm={6} lg={4} className="mt-5">
            <StatisticCard
              icon="document-type-thin"
              variant="primary-light"
              title="Pólizas"
              subtitle="(fecha de emisión)"
              clickable
              onClick={() =>
                handleContractSummaryRequest({
                  filterName: 'issue_date',
                  xlsxName: 'reporte_de_polizas_por_emision',
                  filterSelect: ['policy', 'issue_date']
                })
              }
            />
          </Col>
        </>
      )}

      {ability.can('manage', 'LeadReport') && (
        <>
          <Col sm={6} lg={4} className="mt-5">
            <StatisticCard
              icon="document-type-thin"
              variant="primary-light"
              title="Ventas"
              subtitle="(fecha de creación)"
              clickable
              onClick={() =>
                handleLeadSummaryRequest({
                  renewals: false,
                  filterName: 'created_at',
                  xlsxName: 'reporte_de_ventas',
                  filterSelect: ['lead', 'created_at']
                })
              }
            />
          </Col>
          <Col sm={6} lg={4} className="mt-5">
            <StatisticCard
              icon="document-type-thin"
              variant="primary-light"
              title="Renovaciones"
              subtitle="(fecha de término de póliza origen)"
              clickable
              onClick={() =>
                handleLeadSummaryRequest({
                  renewals: true,
                  filterName: 'renewal_date',
                  xlsxName: 'reporte_de_renovaciones'
                })
              }
            />
          </Col>
        </>
      )}

      {ability.can('manage', 'DebtReport') && (
        <>
          <Col sm={6} lg={4} className="mt-5">
            <StatisticCard
              icon="document-type-thin"
              variant="primary-light"
              title="Deuda"
              clickable
              onClick={() =>
                handleDebtReportRequest({
                  xlsxName: 'reporte_de_deuda',
                  with_debt: true
                })
              }
            />
          </Col>
        </>
      )}

      {ability.can('manage', 'AccountReport') && (
        <>
          <Col sm={6} lg={4} className="mt-5">
            <StatisticCard
              icon="document-type-thin"
              variant="primary-light"
              title="Cuentas"
              clickable
              onClick={() =>
                handleAccountReportRequest({
                  xlsxName: 'reporte_de_cuentas'
                })
              }
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default ReportIndex;
