import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Image, Row } from 'react-bootstrap';

import { IconBtn } from '..';
// import { IconBtn, SimpleCenteredModal } from '..';
import { showLeadQuotationRequest } from '../../requests/leadQuotations';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
// import ApproveQuotation from './ApproveQuotation';
// import CloseQuotation from './CloseQuotation';
import { deleteAdminLeadQuotationRequest } from '../../requests/admin/leadQuotation';
import defaultProfileImg from '../../assets/images/default-profile.png';
import { useDestroyOne } from '../../hooks';

const SelectQuotation = ({
  canCloseQuotation,
  currentLeadQuotation,
  fromAdmin,
  // indexPath,
  isSelected,
  lead,
  setMoreData,
  // setSelectedFile,
  setSelectedQuotations
}) => {
  const [leadQuotation, setLeadQuotation] = useState(currentLeadQuotation);
  // const [modalBody, setModalBody] = useState('');
  // const [modalShow, setModalShow] = useState(false);
  // const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();

  const {
    id,
    insuranceCompanyLogoInfo,
    name,
    parsedAffectCommissionPercentage,
    parsedExemptCommissionPercentage,
    quotation
  } = leadQuotation;
  const quotationTitle = `${name}`;

  // const handleModalClose = () => setModalShow(false);

  const handleShowLeadQuotationRequest = () => {
    // setOnRequest(true);
    showLeadQuotationRequest(id, {
      dispatch,
      successCallback: response => setLeadQuotation(camelCaseEmptyStringRecursive(response.data))
      // callback: () => setOnRequest(false)
    });
  };

  const handleShowLeadQuotation = () => {
    handleShowLeadQuotationRequest();
    window.open(quotation?.fileUrl, '_blank', 'noopener');
  };

  const handleSelectQuotation = () => {
    setSelectedQuotations(prevSelectedQuotations => {
      const exists = prevSelectedQuotations.find(q => q.id === leadQuotation.id);
      if (exists) {
        return prevSelectedQuotations.filter(q => q.id !== leadQuotation.id);
      }
      return [...prevSelectedQuotations, leadQuotation];
    });
  };

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteAdminLeadQuotationRequest,
    parentId: lead.id,
    modelName: 'Cotización',
    nameEndsInA: true,
    setMoreData
  });

  return (
    <Row className={`quotation-select__container ${isSelected ? 'quotation-select__selected' : ''}`}>
      <Col md={1} lg={1} className="align-self-center">
        <div className="profile-avatar">
          {insuranceCompanyLogoInfo && Object.keys(insuranceCompanyLogoInfo).length > 0 ? (
            <div className="custom-file-img mb-3">
              <Image
                src={insuranceCompanyLogoInfo.fileUrl || defaultProfileImg}
                fluid
                alt="image-1"
                style={{ filter: 'grayscale(100%)' }}
              />
            </div>
          ) : (
            <div className="custom-file-img mb-3">
              <Image src={defaultProfileImg} fluid alt="default-profile-img" style={{ filter: 'grayscale(100%)' }} />
            </div>
          )}
        </div>
      </Col>
      <Col md={7} lg={7} className="align-self-center pr-lg-0">
        <p className="list-element">
          {quotationTitle}
          {canCloseQuotation && (
            <>
              <span className="pl-5">{parsedAffectCommissionPercentage}</span>
              <span className="pl-5">{parsedExemptCommissionPercentage}</span>
            </>
          )}
        </p>
      </Col>
      <Col md={1} lg={1} className="align-self-center">
        <Button variant="primary" className="btn-sm" onClick={handleShowLeadQuotation}>
          Ver
        </Button>
      </Col>
      {/* <Col md={2} lg={2} className="align-self-center mt-3">
        <Button variant="primary" className="btn-sm" onClick={() => setSelectedQuotation(id)}>
          Seleccionar
        </Button>
      </Col> */}

      <Col md={2} lg={2} className="align-self-center">
        <Button variant="primary" className="btn-sm" onClick={handleSelectQuotation}>
          {isSelected ? 'Deseleccionar' : 'Seleccionar'}
        </Button>
      </Col>

      {fromAdmin && (
        <Col xs={1} md={1}>
          <IconBtn
            icon="trash"
            variant="transparent"
            className="danger"
            onClick={() => handleShowModal(leadQuotation)}
          />
        </Col>
      )}

      {/* {isSelected &&
        (canCloseQuotation ? (
          <CloseQuotation
            fromAdmin={fromAdmin}
            lead={lead}
            leadQuotation={leadQuotation}
            setLeadQuotation={setLeadQuotation}
            setSelectedFile={setSelectedFile}
            setModalBody={setModalBody}
            setModalShow={setModalShow}
            onRequest={onRequest}
            setOnRequest={setOnRequest}
            setMoreData={setMoreData}
          />
        ) : (
          <Col md={4} className="align-self-center mt-3">
            <ApproveQuotation
              lead={lead}
              leadQuotation={leadQuotation}
              quotationTitle={quotationTitle}
              indexPath={indexPath}
            />
          </Col>
        ))} */}

      {/* <SimpleCenteredModal show={modalShow} body={modalBody} onHide={handleModalClose} /> */}
      <OnDestroyModalComponent />
    </Row>
  );
};

export default SelectQuotation;
