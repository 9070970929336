import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn } from 'formik';

import { AddressFieldsForm, BasicTextArea, FormikInput, FormikNumberFormat, FormikSwitch } from '../../components';

const ContactFields = ({
  parentModel,
  forNestedAttributes,
  values,
  index,
  setFieldValue,
  errors,
  touched,
  userModule
}) => {
  let modelName = parentModel ? `${parentModel}[contactsAttributes]` : 'contact';
  modelName = modelName.includes(parentModel) && forNestedAttributes ? `${modelName}[${index}]` : modelName;

  const title = forNestedAttributes ? `Contacto ${index + 1}` : undefined;

  const insuredUserTooltip = () => {
    return `Al estar habilitado como 'Gestor', <br/>
    el contacto/usuario podrá ingresar <br/>
    a NICO y revisar toda la información <br/>
    relacionada a esta Cuenta.`;
  };

  const comercialTooltip = () => {
    return `Al estar habilitado como 'Comercial', <br/>
    el contacto/usuario recibirá los correos <br/>
    de envío de documentos de NICO <br/>
    (cotizaciones, propuestas, pólizas y <br/>
    endosos) relacionados a esta Cuenta.`;
  };

  return (
    <>
      {title && <p className="section-title mt-5">{title}</p>}
      <Row>
        <Col md={4}>
          <Field name={`${modelName}[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="Nombre completo"
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[phoneNumber]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                label="Teléfono"
                format="+56 # #### ####"
                onValueChange={number => setFieldValue(field.name, number.formattedValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[email]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Correo electrónico"
                placeholder="usuario@correo.com"
                touched={getIn(touched, field.name)}
                error={getIn(errors, field.name)}
                disabled={userModule === 'insured'}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Field name={`${modelName}[position]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="Cargo"
                placeholder="Puesto de trabajo"
                touched={getIn(touched, field.name)}
                error={getIn(errors, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <p className="section-subtitle">Dirección</p>
      <AddressFieldsForm
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        parentModel={modelName}
      />

      <p className="section-subtitle">Marcas de contacto</p>
      <Row className="mb-5">
        <Col xs={6} md={3}>
          <Field name={`${modelName}[isInsuredUser]`}>
            {({ field }) => (
              <FormikSwitch
                {...field}
                field={field}
                label="Gestor"
                tooltipText={insuredUserTooltip()}
                disabled={userModule === 'insured'}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={3}>
          <Field name={`${modelName}[isPrimaryContact]`}>
            {({ field }) => (
              <FormikSwitch {...field} field={field} label="Comercial" tooltipText={comercialTooltip()} />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[comments]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Comentarios"
                placeholder="Ingresa tu comentario..."
                row={2}
                className="p-3"
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default ContactFields;
