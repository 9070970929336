import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { FormikInput, FormikNumberFormat } from '../..';
import { calculateDays, capitalizeFirstLetter, lowerCaseTextFormat, setFieldAttribute } from '../../../services/utils';
import InsuranceEndorsementFormDate from './InsuranceEndorsementFormDate';

const InsuranceEndorsementFormCut = ({ insurancePolicy }) => {
  const { values } = useFormikContext();
  const { endorsementAction, endorsementType } = values.insuranceEndorsement;

  const isRemove = endorsementAction === 'remove';
  const updatePolicyModelName = (isRemove && 'insuranceEndorsement[insuranceItemAttributes]') || 'insuranceEndorsement';

  const typeLabel = {
    rehabilitation: 'Fecha de rehabilitación'
  };

  const actionLabel = {
    cut: { cancellation: 'Fecha de cancelación', annulment: 'Fecha de anulación' },
    decrease: { remove: 'Fecha de eliminación' },
    increase: { extension: 'Fecha de prórroga' }
  };

  const dateLabel = typeLabel[endorsementType] || actionLabel[endorsementType][endorsementAction];

  return (
    <>
      <hr className="w-100 my-5" />
      <p className="section-title">Información actual de la póliza</p>
      <CurrentInsurancePolicy modelName="insurancePolicy" insurancePolicy={insurancePolicy} />

      <hr className="w-100 my-5" />
      <p className="section-title">Selecciona la {lowerCaseTextFormat(dateLabel)}</p>
      <UpdatedInsurancePolicy
        modelName={updatePolicyModelName}
        insurancePolicy={insurancePolicy}
        dateLabel={dateLabel}
        isRemove={isRemove}
      />
    </>
  );
};

const CurrentInsurancePolicy = ({ insurancePolicy, modelName }) => {
  const { affectPremium, currency, exemptPremium, totalNetPremium, validityEnd, validityStart } = insurancePolicy;

  return (
    <Row>
      <Col sm={6} md={4}>
        <Field name={`${modelName}[validityStart]`}>
          {({ field }) => <FormikInput {...field} abbr disabled label="Inicio" value={validityStart} />}
        </Field>
      </Col>
      <Col sm={6} md={4}>
        <Field name={`${modelName}[validityEnd]`}>
          {({ field }) => <FormikInput {...field} abbr disabled label="Término" value={validityEnd} />}
        </Field>
      </Col>
      <Col sm={6} md={4}>
        <Field name={`${modelName}[termDuration]`}>
          {({ field }) => (
            <FormikInput {...field} abbr disabled label="Duración" value={calculateDays(validityStart, validityEnd)} />
          )}
        </Field>
      </Col>
      <Col sm={6} md={4}>
        <Field name={`${modelName}[affectPremium]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              disabled
              suffix={` ${currency}`}
              label="Prima afecta"
              defaultValue={affectPremium}
            />
          )}
        </Field>
      </Col>
      <Col sm={6} md={4}>
        <Field name={`${modelName}[exemptPremium]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              disabled
              suffix={` ${currency}`}
              label="Prima exenta"
              defaultValue={exemptPremium}
            />
          )}
        </Field>
      </Col>
      <Col sm={6} md={4}>
        <Field name={`${modelName}[totalNetPremium]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              disabled
              suffix={` ${currency}`}
              label="Prima total neta"
              defaultValue={totalNetPremium}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

const UpdatedInsurancePolicy = ({ dateLabel, insurancePolicy, modelName, isRemove }) => {
  const { setFieldValue, values } = useFormikContext();
  const { currency, oldInsurancePolicyAttributes, validityEnd, validityStart } = insurancePolicy;
  const { endorsementAction, endorsementDate, endorsementType, originalInsuranceItem } = values.insuranceEndorsement;
  const { variationAffectPremium, variationExemptPremium } = getIn(values, modelName);

  const isRehabilitation = endorsementType === 'rehabilitation';
  const isAnnulment = endorsementAction === 'annulment';
  const disabled = isAnnulment || isRehabilitation;
  const equalDates = validityStart === validityEnd;
  const applyOldValues = isRehabilitation && equalDates;

  const {
    affectPremium: oldAffectPremium = 0,
    exemptPremium: oldExemptPremium = 0
    // validityEnd: oldValidityEnd = ''
  } = oldInsurancePolicyAttributes;

  const defaultDate = (isAnnulment && validityStart) || (isRehabilitation && validityEnd);
  const vAffectPremium = applyOldValues ? oldAffectPremium : variationAffectPremium;
  const vExemptPremium = applyOldValues ? oldExemptPremium : variationExemptPremium;

  const calculateTermDuration = () => {
    const originalTermDuration = calculateDays(validityStart, validityEnd);
    const newTermDuration = calculateDays(validityStart, endorsementDate);
    return { originalTermDuration, newTermDuration };
  };

  const calculatePercentageByDate = () => {
    const { originalTermDuration, newTermDuration } = calculateTermDuration();
    const proportionalPercentage = newTermDuration > 0 ? (newTermDuration * 100) / originalTermDuration : 0;
    const validPercentage = proportionalPercentage === Infinity ? 0 : proportionalPercentage;
    return validPercentage / 100;
  };

  const calculateVariations = ({ originalValue }) => {
    if (!endorsementDate || !originalValue) return { variation: 0, value: 0 };
    const proportionalPercentage = calculatePercentageByDate();
    const valueByType = applyOldValues ? originalValue : -originalValue;
    const variation = valueByType * (1 - proportionalPercentage);
    const value = originalValue * proportionalPercentage;
    return { value, variation };
  };

  const handleSetFieldValues = ({ attribute, originalValue, nameOfModel = modelName }) => {
    const { value, variation } = calculateVariations({ originalValue });
    const variationAttribute = `variation${capitalizeFirstLetter(attribute)}`;
    setFieldAttribute(nameOfModel, attribute, setFieldValue, value, ` ${currency}`);
    setFieldAttribute(nameOfModel, variationAttribute, setFieldValue, variation, ` ${currency}`);
  };

  const handleOnChangePremium = ({ currentObject }) => {
    const { affectPremium, exemptPremium, totalNetPremium } = currentObject;
    handleSetFieldValues({ attribute: 'affectPremium', originalValue: parseFloat(affectPremium) });
    handleSetFieldValues({ attribute: 'exemptPremium', originalValue: parseFloat(exemptPremium) });
    handleSetFieldValues({ attribute: 'totalNetPremium', originalValue: parseFloat(totalNetPremium) });
  };

  const updateCoversValues = () => {
    const { insuranceCoversAttributes } = originalInsuranceItem;
    insuranceCoversAttributes.forEach(({ netPremium }, index) => {
      handleSetFieldValues({
        attribute: 'netPremium',
        originalValue: parseFloat(netPremium),
        nameOfModel: `${modelName}[insuranceCoversAttributes][${index}]`
      });
    });
    handleOnChangePremium({ currentObject: originalInsuranceItem });
  };

  const handleOnChangeEndorsementDate = () => {
    if (isRemove) updateCoversValues();
    else {
      const extractFromModel = applyOldValues ? oldInsurancePolicyAttributes : insurancePolicy;
      handleOnChangePremium({ currentObject: extractFromModel });
    }
  };

  const calculateVariationByAmount = ({ currentPremiumValues, netPremium }) => {
    const { original: originalItemValue, variation: variationItemValue } = currentPremiumValues;
    const oldPercentage = (100 * parseFloat(netPremium)) / parseFloat(originalItemValue) / 100;
    const newNetPremium = parseFloat(variationItemValue) * parseFloat(oldPercentage) + parseFloat(netPremium);
    return { newNetPremium, variationNetPremium: parseFloat(newNetPremium) - parseFloat(netPremium) };
  };

  const updateCoverNetPremium = () => {
    const { affectPremium, exemptPremium, insuranceCoversAttributes } = originalInsuranceItem;
    insuranceCoversAttributes.forEach(({ netPremium, coverageType }, index) => {
      const premiumValues = {
        affect: { original: affectPremium, variation: variationAffectPremium },
        exempt: { original: exemptPremium, variation: variationExemptPremium }
      };
      const currentPremiumValues = premiumValues[coverageType];
      const { newNetPremium, variationNetPremium } = calculateVariationByAmount({ currentPremiumValues, netPremium });
      const coverModelKey = `${modelName}[insuranceCoversAttributes][${index}]`;
      setFieldAttribute(coverModelKey, 'netPremium', setFieldValue, newNetPremium, ` ${currency}`);
      setFieldAttribute(coverModelKey, 'variationNetPremium', setFieldValue, variationNetPremium, ` ${currency}`);
    });
  };

  const handleOnChangeVariationPremium = () => {
    const extractFromModel = isRemove ? originalInsuranceItem : insurancePolicy;
    const { affectPremium, exemptPremium } = extractFromModel;

    const newAffectPremium = parseFloat(affectPremium) + parseFloat(vAffectPremium);
    const newExemptPremium = parseFloat(exemptPremium) + parseFloat(vExemptPremium);
    const newTotalNetPremium = parseFloat(newAffectPremium + newExemptPremium);
    const variationTotalNetPremium = parseFloat(vAffectPremium + vExemptPremium);

    setFieldAttribute(modelName, 'affectPremium', setFieldValue, newAffectPremium, ` ${currency}`);
    setFieldAttribute(modelName, 'exemptPremium', setFieldValue, newExemptPremium, ` ${currency}`);
    setFieldAttribute(modelName, 'totalNetPremium', setFieldValue, newTotalNetPremium, ` ${currency}`);
    setFieldAttribute(modelName, 'variationTotalNetPremium', setFieldValue, variationTotalNetPremium, ` ${currency}`);
    if (isRemove) updateCoverNetPremium();
  };

  useEffect(handleOnChangeEndorsementDate, [endorsementDate]);
  useEffect(handleOnChangeVariationPremium, [variationAffectPremium, variationExemptPremium]);

  return (
    <>
      <InsuranceEndorsementFormDate
        disabled={disabled}
        modelName={isRemove ? 'insuranceEndorsement' : modelName}
        dateLabel={dateLabel}
        termDuration={calculateDays(validityStart, endorsementDate)}
        insurancePolicy={insurancePolicy}
        defaultDate={defaultDate}
      />

      <p className="section-subtitle">Nuevos valores</p>
      <NewInsurancePolicyValues modelName={modelName} currency={currency} disabled={isAnnulment} />
    </>
  );
};

const NewInsurancePolicyValues = ({ currency, disabled, modelName }) => {
  const { errors, touched, values } = useFormikContext();

  return (
    <Row>
      <Col sm={6} md={3}>
        <Field name={`${modelName}[parsedVariationAffectPremium]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              allowNegative
              suffix={` ${currency}`}
              label="Variación prima afecta"
              defaultValue={getIn(values, `${modelName}[variationAffectPremium]`)}
              fieldName={`${modelName}[variationAffectPremium]`}
              error={getIn(errors, `${modelName}[variationAffectPremium]`)}
              touched={getIn(touched, `${modelName}[variationAffectPremium]`)}
              disabled={disabled}
            />
          )}
        </Field>
      </Col>
      <Col sm={6} md={3}>
        <Field name={`${modelName}[affectPremium]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              disabled
              allowNegative
              label="Nueva prima afecta"
              suffix={` ${currency}`}
            />
          )}
        </Field>
      </Col>
      <Col sm={6} md={3}>
        <Field name={`${modelName}[parsedVariationExemptPremium]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              allowNegative
              suffix={` ${currency}`}
              label="Variación prima exenta"
              defaultValue={getIn(values, `${modelName}[variationExemptPremium]`)}
              fieldName={`${modelName}[variationExemptPremium]`}
              error={getIn(errors, `${modelName}[variationExemptPremium]`)}
              touched={getIn(touched, `${modelName}[variationExemptPremium]`)}
              disabled={disabled}
            />
          )}
        </Field>
      </Col>
      <Col sm={6} md={3}>
        <Field name={`${modelName}[exemptPremium]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              disabled
              allowNegative
              label="Nueva prima exenta"
              suffix={` ${currency}`}
            />
          )}
        </Field>
      </Col>
      <Col sm={6} md={3}>
        <Field name={`${modelName}[parsedVariationTotalNetPremium]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              disabled
              allowNegative
              suffix={` ${currency}`}
              label="Variación prima total neta"
              defaultValue={getIn(values, `${modelName}[variationTotalNetPremium]`)}
              fieldName={`${modelName}[variationTotalNetPremium]`}
              error={getIn(errors, `${modelName}[variationTotalNetPremium]`)}
              touched={getIn(touched, `${modelName}[variationTotalNetPremium]`)}
            />
          )}
        </Field>
      </Col>
      <Col sm={6} md={3}>
        <Field name={`${modelName}[totalNetPremium]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              disabled
              allowNegative
              label="Nueva prima total neta"
              suffix={` ${currency}`}
              error={getIn(errors, `${modelName}[totalNetPremium]`)}
              touched={getIn(touched, `${modelName}[totalNetPremium]`)}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

export default InsuranceEndorsementFormCut;
