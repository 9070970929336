export const leadProps = ({ lead }) => {
  const { insurancePolicyProposal, isRenewal, renewableInsurancePolicy } = lead;
  const {
    contractProposalInfo,
    insuranceCategoryName,
    insuranceCompanyExecutiveEmail,
    insuranceCompanyFullName,
    insuranceCompanyName,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber,
    subjectMatter
  } = insurancePolicyProposal;

  const proposalTypeName = `Propuesta de ${isRenewal ? 'renovación' : 'contratación'}`;

  return {
    modalTitle: { textConnector: 'una', text: proposalTypeName, recipient: insuranceCompanyFullName },
    to: [insuranceCompanyExecutiveEmail],
    subject: `NICO Seguros - ${proposalTypeName} n° ${policyNumber} para ${insuranceCompanyName}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras la ${proposalTypeName.toLowerCase()} n° ${policyNumber}, de NICO Corredores de Seguros SpA a ${insuranceCompanyFullName}, según el siguiente detalle:<br><br>${
      isRenewal ? `N° de póliza a renovar: ${renewableInsurancePolicy.policyNumber}<br>` : ''
    }
    Nombre asegurado: ${insuredPersonName}<br>RUT asegurado: ${insuredPersonNationalIdentification}<br>Ramo: ${insuranceCategoryName}<br>Materia: ${subjectMatter}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: [{ ...contractProposalInfo, cleanFilename: `NICO Seguros - ${proposalTypeName} n° ${policyNumber}` }]
  };
};

export const leadQuotationProps = ({ lead, selectedQuotations }) => {
  const { account, leadQuotations, subjectMatter } = lead;
  const filteredLeadQuotations = leadQuotations.filter(leadQuotation =>
    selectedQuotations.find(q => q.id === leadQuotation.id)
  );
  const commercialContactEmails = account.commercialContacts.map(contact => contact.email);

  const leadQuotationAttachments = filteredLeadQuotations.map(leadQuotation => ({
    ...leadQuotation.quotation,
    cleanFilename: `NICO Seguros - Cotización ${leadQuotation.name}`
  }));

  return {
    modalTitle: { textConnector: 'una', text: 'cotización', recipient: account.name },
    to: [...commercialContactEmails, account.insuranceBrokerEmail],
    subject: `NICO Seguros - Cotización para ${account.name}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras cotizaciones de seguro solicitadas por ${account.name} para proteger su ${subjectMatter}.<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: leadQuotationAttachments
  };
};

export const insurancePolicyProps = ({ insurancePolicy }) => {
  const {
    account = {},
    contractInfo = {},
    insuranceCategoryName,
    insuranceCompany = {},
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber,
    subjectMatter
  } = insurancePolicy;

  const commercialContactEmails = account.commercialContacts.map(contact => contact.email);

  return {
    modalTitle: { textConnector: 'una', text: 'póliza', recipient: account.name },
    to: [...commercialContactEmails, account.insuranceBrokerEmail],
    subject: `NICO Seguros - Póliza n° ${policyNumber} para ${account.name}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras la póliza n° ${policyNumber}, donde encontrarás todos los detalles de la siguiente cobertura:<br><br>N° de póliza: ${policyNumber}<br>Nombre asegurado: ${insuredPersonName}<br>RUT asegurado: ${insuredPersonNationalIdentification}<br>Compañía de seguros: ${insuranceCompany.fullName}<br>Ramo: ${insuranceCategoryName}<br>Materia: ${subjectMatter}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: [{ ...contractInfo, cleanFilename: `NICO Seguros - Póliza n° ${policyNumber}` }]
  };
};

export const insuranceEndorsementProps = ({ insurancePolicy }) => {
  const {
    account,
    insuranceCategoryName,
    insuranceEndorsementsAttributes: insuranceEndorsements,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber,
    subjectMatter
  } = insurancePolicy;

  const commercialContactEmails = account.commercialContacts.map(contact => contact.email);
  const endorsements = insuranceEndorsements.filter(obj => Object.keys(obj.companyEndorsementInfo).length > 0);

  const insuranceEndorsementAttachments = endorsements.map(insuranceEndorsement => ({
    ...insuranceEndorsement.companyEndorsementInfo,
    cleanFilename: `NICO Seguros - Endoso n° ${insuranceEndorsement.id} a póliza n° ${policyNumber}`
  }));

  return {
    modalTitle: { textConnector: 'un', text: 'endoso', recipient: account.name },
    to: [...commercialContactEmails, account.insuranceBrokerEmail],
    subject: `NICO Seguros - Endosos a póliza n° ${policyNumber} para ${account.name}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras los endosos de la póliza n° ${policyNumber}, donde encontrarás las modificaciones realizadas, según el siguiente detalle:<br><br>N° de póliza: ${policyNumber}<br>Nombre asegurado: ${insuredPersonName}<br>RUT asegurado: ${insuredPersonNationalIdentification}<br>Ramo: ${insuranceCategoryName}<br>Materia: ${subjectMatter}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: insuranceEndorsementAttachments
  };
};

export const insuranceProposalEndorsementProps = ({ insuranceEndorsement, insurancePolicy }) => {
  const {
    insuranceCategoryName,
    insuranceCompanyExecutiveEmail,
    insuranceCompanyFullName,
    insuranceCompanyName,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber,
    subjectMatter
  } = insurancePolicy;

  return {
    modalTitle: { textConnector: 'una', text: 'propuesta de endoso', recipient: insuranceCompanyFullName },
    to: [insuranceCompanyExecutiveEmail],
    subject: `NICO Seguros - Propuesta de endoso a póliza n° ${policyNumber} para ${insuranceCompanyName}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras la propuesta de endoso a la póliza n° ${policyNumber}, de NICO Corredores de Seguros SpA a ${insuranceCompanyFullName}, según el siguiente detalle:<br><br>N° de póliza: ${policyNumber}<br>Nombre asegurado: ${insuredPersonName}<br>RUT asegurado: ${insuredPersonNationalIdentification}<br>Ramo: ${insuranceCategoryName}<br>Materia: ${subjectMatter}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: [
      {
        ...insuranceEndorsement.endorsementSummaryInfo,
        cleanFilename: `NICO Seguros - Propuesta de endoso n° ${insuranceEndorsement.id} a póliza n° ${policyNumber}`
      }
    ]
  };
};

export const insuranceCompanyEndorsementProps = ({ insuranceEndorsement, insurancePolicy }) => {
  const {
    account,
    insuranceCategoryName,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber,
    subjectMatter
  } = insurancePolicy;

  const commercialContactEmails = account.commercialContacts.map(contact => contact.email);

  return {
    modalTitle: { textConnector: 'un', text: 'endoso', recipient: account.name },
    to: [...commercialContactEmails, account.insuranceBrokerEmail],
    subject: `NICO Seguros - Endoso n° ${insuranceEndorsement.id} a póliza n° ${policyNumber} para ${account.name}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras el endoso n° ${insuranceEndorsement.id} a la póliza n° ${policyNumber}, donde encontrarás las modificaciones realizadas, según el siguiente detalle:<br><br>N° de póliza: ${policyNumber}<br>N° de endoso: ${insuranceEndorsement.id}<br>Tipo de endoso: ${insuranceEndorsement.translatedEndorsementType}<br>Nombre asegurado: ${insuredPersonName}<br>RUT asegurado: ${insuredPersonNationalIdentification}<br>Ramo: ${insuranceCategoryName}<br>Materia: ${subjectMatter}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: [
      {
        ...insuranceEndorsement.companyEndorsementInfo,
        cleanFilename: `NICO Seguros - Endoso n° ${insuranceEndorsement.id} a póliza n° ${policyNumber}`
      }
    ]
  };
};

export const beneficiaryMovementsProps = ({ beneficiaryMovement, insurancePolicy, currentUser }) => {
  const {
    account = {},
    insuranceCompanyExecutiveEmail,
    insuranceCompanyFullName,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber
  } = insurancePolicy;

  const { additionsQuantity, exclusionsQuantity } = beneficiaryMovement;

  return {
    modalTitle: { textConnector: 'nuevos', text: 'movimientos', recipient: insuranceCompanyFullName },
    to: [currentUser.email, insuranceCompanyExecutiveEmail, account.insuranceBrokerEmail],
    subject: `NICO Seguros - Movimientos en póliza n° ${policyNumber}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras los nuevos movimientos de nómina de la póliza n° ${policyNumber}, donde encontrarás todos los detalles de los siguientes movimientos:<br><br>N° de póliza: ${policyNumber}<br>Nombre contratante: ${insuredPersonName}<br>RUT contratante: ${insuredPersonNationalIdentification}<br>Número de incorporaciones: ${additionsQuantity}<br>Número de exclusiones: ${exclusionsQuantity}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: [
      {
        ...beneficiaryMovement?.movementSummaryInfo,
        cleanFilename: `NICO Seguros - Movimientos en póliza n° ${policyNumber}`
      }
    ]
  };
};
