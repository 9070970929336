import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import sendDocumentAdminRequest from '../../requests/admin/adminSharedEndpoints';
import sendDocumentRequest from '../../requests/sharedEndpoints';
import { SimpleCenteredModal } from '..';
import { ModalBodyIcon, SendEmailForm } from '.';

const sendEmailAttributes = {
  attachments: [],
  body: '',
  from: '',
  subject: '',
  to: ''
};

const SendDocumentEmail = ({
  fromAdmin,
  executiveModel,
  extraCloseFunction = () => null,
  fileSection = false,
  fileSectionLabel = '',
  fileSectionDescription = ''
}) => {
  const { user: currentUser } = useSelector(state => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const openEmailModal = () => setModalShow(true);

  const closeModal = extraCloseState => {
    setModalShow(false);
    extraCloseFunction();
    extraCloseState(false);
  };

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Documentos enviados con éxito' }));
    setModalShow(false);
    extraCloseFunction();
  };

  const handleSendEmailRequest = (params, setSubmitting) => {
    const sendParams = snakeCaseKeys({ ...params, executiveModel });
    const endpointRequest = fromAdmin ? sendDocumentAdminRequest : sendDocumentRequest;
    endpointRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const SendEmailModal = ({
    attachments = [],
    body = '',
    extraCloseState = () => null,
    modalTitle = {},
    subject = '',
    to = ''
  }) => {
    const [sendEmail, setSendEmail] = useState({ ...sendEmailAttributes, from: currentUser.email });
    const [temporalDropzoneFiles, setTemporalDropzoneFiles] = useState([]);

    const handleOnChangeValues = () => {
      setSendEmail({ ...sendEmail, attachments, body, subject, to, temporalDropzoneFiles });
    };

    useEffect(handleOnChangeValues, [attachments, body, subject, to, temporalDropzoneFiles]);

    return (
      <SimpleCenteredModal
        size="md"
        show={modalShow}
        body={
          <ModalBodyIcon
            icon="mail"
            iconVariant="success"
            content={`Estás por enviar ${modalTitle.textConnector}`}
            highlightedText={modalTitle.text}
            highlightedVariant="light-success"
            recipient={modalTitle.recipient}
            formContent={
              <SendEmailForm
                sendEmail={sendEmail}
                formRequest={handleSendEmailRequest}
                handleModalClose={closeModal}
                temporalDropzoneFiles={temporalDropzoneFiles}
                setTemporalDropzoneFiles={setTemporalDropzoneFiles}
                fileSection={fileSection}
                fileSectionLabel={fileSectionLabel}
                fileSectionDescription={fileSectionDescription}
              />
            }
          />
        }
        onHide={() => closeModal(extraCloseState)}
      />
    );
  };

  return { openEmailModal, SendEmailModal };
};

export default SendDocumentEmail;
