import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import { SimpleCenteredModal } from '../..';
import { sendAlert } from '../../../actions/utils';
import {
  createAdminLeadQuotationRequest,
  sendQuotationAdminLeadQuotationRequest
} from '../../../requests/admin/leadQuotation';
import LeadSuccessCloseModal from '../../../screens/Lead/LeadSuccessCloseModal';
import { scrollToTop } from '../../../services/utils';
import sendDocumentEmail from '../../Shared/SendDocumentEmail';
import { leadQuotationProps } from '../../Shared/SendEmailModalProps';
import { IconBtn } from '../../Utils/Button';
import { InfoCard } from '../../Utils/Cards';
import basicLeadQuotation from '../basicLeadQuotation';
import CloseQuotationModal from '../CloseQuotationModal';
import LeadQuotationForm from '../LeadQuotationForm';
import SelectQuotation from '../SelectQuotation';
import BackToStepModal from '../BackToStepModal';
import { ModalBodyIcon } from '../../Shared';
import { sendQuotationLeadQuotationRequest } from '../../../requests/leadQuotations';
import ApproveQuotation from '../ApproveQuotation';

const InfoCardLeadQuotation = ({
  fromAdmin,
  canCloseQuotation,
  lead,
  leadQuotationMessage,
  modelUrl,
  setMoreData,
  isRenewal
}) => {
  const { leadQuotations, selectedLeadQuotation } = lead;

  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedQuotation, setSelectedQuotation] = useState(selectedLeadQuotation || null);
  const [selectedQuotations, setSelectedQuotations] = useState(selectedLeadQuotation ? [selectedLeadQuotation] : []);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const dispatch = useDispatch();

  const { SendEmailModal, openEmailModal } = sendDocumentEmail({
    fromAdmin,
    executiveModel: { id: lead.id, name: 'Lead' }
  });

  const hasQuotations = leadQuotations.length > 0;
  // const isDisabled = !selectedQuotation || !selectedFile;
  const isDisabled = selectedQuotations.length === 0;

  const handleModalSuccessClose = () => {
    setMoreData(m => !m);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleCreateRequest = values => {
    const { quotation } = values.leadQuotation;
    const sendParams = snakecaseKeys(values);
    sendParams.lead_quotation.quotation = quotation;
    createAdminLeadQuotationRequest(lead.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => {
        handleModalClose();
        dispatch(sendAlert({ kind: 'success', message: 'Cotización cargada con éxito' }));
        setMoreData(m => !m);
      }
    });
  };

  const handleCreateLeadQuotation = () => {
    setModalShow(true);
    setModalBody(
      <LeadQuotationForm
        action="new"
        leadQuotation={basicLeadQuotation}
        show={modalShow}
        formRequest={handleCreateRequest}
        onHide={handleModalClose}
        insuranceCategory={lead.insuranceCategory}
      />
    );
  };

  const handleCloseLeadQuotation = isSuccess => {
    setModalShow(true);
    setModalBody(
      <CloseQuotationModal
        lead={lead}
        modelUrl={modelUrl}
        fromAdmin={fromAdmin}
        isSuccess={isSuccess}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        selectedQuotation={selectedQuotation}
        handleModalClose={handleModalClose}
        setShowSuccessModal={setShowSuccessModal}
      />
    );
  };

  const purgeSelectedFile = () => setSelectedFile(null);

  useEffect(scrollToTop, []);
  useEffect(purgeSelectedFile, [selectedQuotation]);

  useEffect(() => {
    if (selectedQuotations.length === 1) {
      setSelectedQuotation(selectedQuotations[0]);
    } else {
      setSelectedQuotation(null);
    }
  }, [selectedQuotations]);

  return (
    <>
      {showSuccessModal && <LeadSuccessCloseModal showModal={showSuccessModal} handleClose={handleModalSuccessClose} />}

      <InfoCard
        title="Cotizaciones"
        className="bg-light-gray bg-children-inherit mb-5"
        iconBtnComponent={
          canCloseQuotation &&
          fromAdmin && (
            <div className="d-flex">
              <Button className="mr-4" onClick={() => null}>
                Reservar
              </Button>

              <IconBtn
                icon="software-upload"
                variant="primary-dark"
                className="no-shadow"
                onClick={handleCreateLeadQuotation}
              >
                Cargar Cotización
              </IconBtn>
            </div>
          )
        }
      >
        <CardMessage hasQuotations={hasQuotations} leadQuotationMessage={leadQuotationMessage} />

        <CardQuotationBody
          lead={lead}
          modelUrl={modelUrl}
          fromAdmin={fromAdmin}
          handleSubmitButton={handleCloseLeadQuotation}
          selectedQuotation={selectedQuotation}
          setSelectedQuotation={setSelectedQuotation}
          selectedQuotations={selectedQuotations}
          setSelectedQuotations={setSelectedQuotations}
          setSelectedFile={setSelectedFile}
          setMoreData={setMoreData}
          isDisabled={isDisabled}
          canCloseQuotation={canCloseQuotation}
          hasQuotations={hasQuotations}
          openEmailModal={openEmailModal}
          isRenewal={isRenewal}
        />

        <SimpleCenteredModal show={modalShow} body={modalBody} onHide={handleModalClose} />

        <SendEmailModal {...leadQuotationProps({ lead, selectedQuotations })} />
      </InfoCard>
    </>
  );
};

const CardMessage = ({ hasQuotations, leadQuotationMessage }) =>
  hasQuotations ? (
    <div className="mt-4">
      {leadQuotationMessage.full.map((message, index) => (
        <p key={`card-message-${index.toString()}`} className="mb-4">
          {message}
        </p>
      ))}
    </div>
  ) : (
    <p className="section-title text-center d-block mt-5">{leadQuotationMessage.empty}</p>
  );

const CardQuotationBody = ({ fromAdmin, handleSubmitButton, isDisabled, lead, modelUrl, ...props }) => {
  const {
    canCloseQuotation,
    setMoreData,
    setSelectedFile,
    selectedQuotation,
    setSelectedQuotation,
    setSelectedQuotations,
    selectedQuotations,
    hasQuotations,
    openEmailModal,
    isRenewal
  } = props;

  const [modalBackToStep1, setModalBackToStep1] = useState(false);
  const [modalBackToStepBody, setModalBackToStepBody] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  // const foundQuotation = lead.leadQuotations.find(quotation => quotation.id === selectedQuotation);

  const { id, validAcceptanceKey } = selectedQuotation || {};

  const { commercialContacts } = lead.account;
  const commercialContactEmails = commercialContacts.map(contact => contact.email);
  const stringContactEmails = commercialContactEmails.reduce((acc, current) => {
    const str = acc ? `${acc}, ` : '';
    return `${str} ${current}`;
  }, '');

  const handleModalClose = () => {
    setModalBackToStep1(false);
    setModalBackToStepBody('');
  };

  const handleRequestApprovalModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleBackToStep1 = () => {
    setModalBackToStep1(true);
    setModalBackToStepBody(
      <BackToStepModal
        handleModalClose={handleModalClose}
        isAdmin={fromAdmin}
        lead={lead}
        setMoreData={setMoreData}
        isRenewal={isRenewal}
      />
    );
  };

  const handleSendQuotationRequest = () => {
    setOnRequest(true);

    const requiredFunctions = {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Solicitud enviada con éxito' }));
        setSelectedQuotation({ ...selectedQuotation, validAcceptanceKey: true });
      },
      callback: () => {
        setOnRequest(false);
        setModalShow(false);
      }
    };

    const sendApprovalRequest = fromAdmin ? sendQuotationAdminLeadQuotationRequest : sendQuotationLeadQuotationRequest;
    return fromAdmin ? sendApprovalRequest(lead.id, id, requiredFunctions) : sendApprovalRequest(id, requiredFunctions);
  };

  const handleOnClick = () => {
    setModalShow(true);
    setModalBody(
      <ModalBodyIcon
        icon="file-add"
        highlightedText="Solicitar aprobación al cliente"
        highlightedVariant="light-warning"
        subContent={
          <>
            <p>
              Enviaremos un correo a <span>{stringContactEmails}</span> con un link donde podrá ver la cotización
              seleccionada y aprobar su contratación.
            </p>
            <p>Cuando tu cliente apruebe, esta oportunidad pasará automáticamente al cierre exitoso.</p>
          </>
        }
        handleModalClose={() => setModalShow(false)}
        formRequest={handleSendQuotationRequest}
      />
    );
  };

  return (
    <>
      {lead.leadQuotations.map(item => (
        <SelectQuotation
          key={item.id}
          lead={lead}
          fromAdmin={fromAdmin}
          currentLeadQuotation={item}
          setSelectedQuotation={setSelectedQuotation}
          setSelectedQuotations={setSelectedQuotations}
          isSelected={selectedQuotations.find(q => q.id === item.id)}
          setSelectedFile={setSelectedFile}
          setMoreData={setMoreData}
          canCloseQuotation={canCloseQuotation}
          indexPath={modelUrl}
        />
      ))}

      <Row>
        <Col md={6} lg={6} className="mt-4">
          {canCloseQuotation && (
            <Row className="d-flex align-items-center">
              <Col md={4} lg={4}>
                <Button block variant="cancel" onClick={() => handleBackToStep1()}>
                  Volver a etapa 1
                </Button>
              </Col>

              <Col md={4} lg={4}>
                <Button block variant="strong-danger" className="no-shadow" onClick={() => handleSubmitButton(false)}>
                  Cierre sin éxito
                </Button>
              </Col>
            </Row>
          )}
        </Col>
        <Col md={6} lg={6} className="mt-4">
          {hasQuotations && (
            <Row className="d-flex align-items-center justify-content-end">
              {canCloseQuotation ? (
                <>
                  <Col md={5} lg={5}>
                    <Button
                      block
                      variant="submit"
                      onClick={handleOnClick}
                      disabled={!selectedQuotation || !stringContactEmails || onRequest || validAcceptanceKey}
                    >
                      {validAcceptanceKey ? 'Solicitud enviada' : 'Solicitar aprobación'}
                    </Button>
                  </Col>
                  <Col md={4} lg={3}>
                    <Button block type="submit" variant="primary" onClick={openEmailModal} disabled={isDisabled}>
                      Enviar
                    </Button>
                  </Col>
                  <Col md={4} lg={3}>
                    <Button
                      block
                      type="submit"
                      variant="submit"
                      className="no-shadow"
                      onClick={() => handleSubmitButton(true)}
                      disabled={!selectedQuotation}
                    >
                      Aprobar
                    </Button>
                  </Col>
                </>
              ) : (
                <Col md={4} lg={3}>
                  <ApproveQuotation
                    lead={lead}
                    leadQuotation={selectedQuotation}
                    quotationTitle={selectedQuotation?.name}
                    indexPath={modelUrl}
                    disabled={!selectedQuotation}
                  />
                </Col>
              )}
            </Row>
          )}
        </Col>
      </Row>

      <SimpleCenteredModal show={modalBackToStep1} body={modalBackToStepBody} onHide={handleModalClose} />
      <SimpleCenteredModal show={modalShow} body={modalBody} onHide={handleRequestApprovalModalClose} />
    </>
  );
};

export default InfoCardLeadQuotation;
