import React, { useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { AccountRemoteSelector } from '../../InsurancePolicies';
import { IconBtn } from '../../Utils/Button';
import IconGG from '../../Utils/Icon';
import { FormikInput } from '../../Utils/Input';
import { InsuranceBrokerRemoteSelector } from '../../Shared';
import ExecutiveManagerRemoteSelector from '../../Shared/ExecutiveManagerRemoteSelector';
import AccountModalForm from './AccountModalForm';
import { SimpleCenteredModal } from '../../Utils/Modal';

const AccountSection = ({ allowUpdate, setAllowUpdate, fromAdmin, modelName, userModule }) => {
  const { errors, touched, values } = useFormikContext();
  const [modalShow, setModalShow] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const { insuranceBrokerId } = getIn(values, modelName);
  const handleModalClose = () => setModalShow(false);

  const tooltipTextResponsibleEmail = () => {
    return `Ingresa el correo electrónico de la persona a cargo de la gestión de esta póliza.<br/>
    Esta persona puede ser distinta a los contactos de la Cuenta, el Contratante y el Asegurado.<br/>
    Este correo será usado para el envío de cobranza, propuestas, endosos, etc.`;
  };

  const tooltipTextAccount = () => {
    return `Persona natural o jurídica a quién está asociada la Póliza.<br/>
    No necesariamente es igual al Contratante o Asegurado`;
  };

  const showInsuranceBrokerSelector = () => {
    return fromAdmin || userModule === 'insured';
  };

  return (
    <>
      <section className="form-section mb-5 w-100 account-section">
        <p className="section-title">Información de la Cuenta</p>

        <Row className="account-section__form">
          {showInsuranceBrokerSelector() && (
            <Col md={4} lg={4}>
              <Field name={`${modelName}[insuranceBrokerId]`}>
                {({ field }) => (
                  <InsuranceBrokerRemoteSelector
                    fromAdmin={fromAdmin}
                    field={field}
                    modelName={modelName}
                    tooltipText="Usuario Corredor a quien pertenece la Cuenta (Corredor, Partner o Directo)."
                  />
                )}
              </Field>
            </Col>
          )}
          <Col md={4} lg={4}>
            <Field name={`${modelName}[accountId]`}>
              {({ field }) => (
                <AccountRemoteSelector
                  key={refreshKey} // Forzar actualización
                  field={field}
                  modelName={modelName}
                  fromAdmin={fromAdmin}
                  disabled={fromAdmin && !insuranceBrokerId}
                  tooltipText={tooltipTextAccount()}
                  userModule={userModule}
                />
              )}
            </Field>
          </Col>
          {fromAdmin && (
            <Col md={4} lg={4}>
              <Field name={`${modelName}[executiveManagerId]`}>
                {({ field }) => (
                  <ExecutiveManagerRemoteSelector
                    allowUpdate={allowUpdate}
                    setAllowUpdate={setAllowUpdate}
                    field={field}
                    modelName={modelName}
                    fromAdmin={fromAdmin}
                    // disabled={fromAdmin && !accountId}
                    tooltipText="Ejecutivo NICO que atenderá esta Póliza"
                  />
                )}
              </Field>
            </Col>
          )}
          <Col md={4} lg={4}>
            <Field name={`${modelName}[responsibleEmail]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Correo encargado"
                  placeholder="Correo electrónico"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  tooltipText={tooltipTextResponsibleEmail()}
                />
              )}
            </Field>
          </Col>
          <Col sm={12} md={4} lg={4} className="d-flex align-items-center">
            <Alert variant="warning" className="account-section__alert mb-0">
              <IconGG className="mr-2" icon="danger" size="sm" />
              <p className="detail font-weight-bold">
                Si no encuentras la Cuenta, puedes crear una nueva a continuación.
              </p>
            </Alert>
          </Col>
          <Col sm={12} md={4} lg={4} className="d-flex align-items-center">
            <IconBtn
              icon="add"
              className="mt-lg-0"
              onClick={() => setModalShow(true)} // disabled={onRequest}
              disabled={fromAdmin && !insuranceBrokerId}
            >
              Nueva cuenta
            </IconBtn>
          </Col>
        </Row>

        <SimpleCenteredModal
          size="xl"
          title="Crear nueva cuenta"
          show={modalShow}
          onHide={handleModalClose}
          body={
            <AccountModalForm
              userModule={userModule}
              insuranceBrokerId={insuranceBrokerId}
              fromAdmin={fromAdmin}
              handleCancelButton={handleModalClose}
              setRefreshKey={setRefreshKey}
            />
          }
        />
      </section>

      <hr className="w-100 mb-5" />
    </>
  );
};

export default AccountSection;
