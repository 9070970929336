import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import { InfoCard, SimpleCenteredModal } from '../../../components';
import { InfoBox } from '../../../components/Shared';
import sendDocumentEmail from '../../../components/Shared/SendDocumentEmail';
import {
  insuranceCompanyEndorsementProps,
  insuranceEndorsementProps,
  insuranceProposalEndorsementProps
} from '../../../components/Shared/SendEmailModalProps';
import { useDestroyOne } from '../../../hooks';
import { deleteAdminInsuranceEndorsementRequest } from '../../../requests/admin/insuranceEndorsements';
import InsuranceEndorsementColumns from '../../AdminScreens/InsuranceEndorsement/InsuranceEndorsementColumns';
import endorsementObject from './endorsementObject';
import { deleteInsuranceEndorsementRequest } from '../../../requests/insuranceEndorsement';
import { deleteInsuredInsuranceEndorsementRequest } from '../../../requests/insured/insuredInsuranceEndorsements';

const InfoCardEndorsement = ({
  canEditEndorsements,
  fromAdmin,
  insurancePolicy,
  setMoreData,
  canManageEndorsement,
  userModule
}) => {
  const [insuranceEndorsement, setInsuranceEndorsement] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sendEndorsements, setSendEndorsements] = useState(false);

  const { currency, insuranceEndorsementsAttributes } = insurancePolicy;

  const hasRestrictedRecord =
    insuranceEndorsementsAttributes.filter(record => record.restricted && record.status === 'updated').length > 0;

  const allCompanyEndorsements = insuranceEndorsementsAttributes.filter(
    obj => Object.keys(obj.companyEndorsementInfo).length > 0
  );

  const endorsementsWithIndex = insuranceEndorsementsAttributes.map((endorsement, index) => ({
    ...endorsement,
    index: index + 1
  }));

  const endorsementInfo = endorsementObject({ insurancePolicy, setModalShow });

  const { SendEmailModal, openEmailModal } = sendDocumentEmail({
    fromAdmin,
    executiveModel: { id: insurancePolicy.id, name: 'InsurancePolicy' }
  });

  const handleOpenEmailModal = () => {
    if (modalShow) {
      setModalShow(false);
      openEmailModal();
    }
  };

  useEffect(handleOpenEmailModal, [insuranceEndorsement]);

  return (
    <InfoCard title="Endosos" object={endorsementInfo}>
      <SimpleCenteredModal
        size="xxl"
        title={
          <>
            Endosos
            {fromAdmin && allCompanyEndorsements.length > 0 && (
              <Button
                className="btn-sm ml-4"
                onClick={() => {
                  setSendEndorsements(true);
                  setInsuranceEndorsement({});
                  handleOpenEmailModal();
                }}
              >
                Enviar endosos
              </Button>
            )}
          </>
        }
        show={modalShow}
        body={
          <>
            {fromAdmin && hasRestrictedRecord && (
              <InfoBox
                icon="danger"
                variant="warning"
                text="Se ha aplicado un nuevo endoso a la póliza, debes actualizar las propuestas de endoso generadas 
                      anteriormente para tener la opción de 'Aplicar endoso'."
              />
            )}
            <InsuranceEndorsementDatatable
              fromAdmin={fromAdmin}
              canManageEndorsement={canManageEndorsement}
              currency={currency}
              insurancePolicy={insurancePolicy}
              insuranceEndorsements={endorsementsWithIndex}
              setInsuranceEndorsement={setInsuranceEndorsement}
              canEditEndorsements={canEditEndorsements}
              setMoreData={setMoreData}
              userModule={userModule}
            />
          </>
        }
        onHide={() => {
          setModalShow(false);
          setSendEndorsements(false);
        }}
      />

      {sendEndorsements ? (
        <SendEmailModal {...insuranceEndorsementProps({ insurancePolicy })} extraCloseState={setSendEndorsements} />
      ) : (
        <SendEmailModal {...insuranceProposalEndorsementProps({ insuranceEndorsement, insurancePolicy })} />
      )}
      {insuranceEndorsement.isCompanyEndorsement && (
        <SendEmailModal {...insuranceCompanyEndorsementProps({ insuranceEndorsement, insurancePolicy })} />
      )}
    </InfoCard>
  );
};

const InsuranceEndorsementDatatable = ({
  canEditEndorsements,
  canManageEndorsement,
  currency,
  fromAdmin,
  insuranceEndorsements,
  insurancePolicy,
  setInsuranceEndorsement,
  setMoreData,
  userModule
}) => {
  const getDeleteRequest = () => {
    const pathMap = {
      admin: deleteAdminInsuranceEndorsementRequest,
      broker: deleteInsuranceEndorsementRequest,
      insured: deleteInsuredInsuranceEndorsementRequest
    };

    return pathMap[userModule];
  };

  const { OnDestroyModalComponent, handleShowModal } = useDestroyOne({
    deleteRequest: getDeleteRequest(),
    parentId: insurancePolicy.id,
    modelName: 'Endoso',
    nameEndsInA: false,
    setMoreData
  });

  return (
    <>
      <DataTable
        noHeader
        columns={InsuranceEndorsementColumns({
          canEditEndorsements,
          currency,
          fromAdmin,
          handleShowModal,
          insurancePolicy,
          setInsuranceEndorsement,
          canManageEndorsement,
          userModule
        })}
        data={insuranceEndorsements}
        totalRows={insuranceEndorsements.length}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default InfoCardEndorsement;
