import React from 'react';
import { getIn, useFormikContext } from 'formik';

import { useFetchForRemoteSelect } from '../../hooks';
import {
  debounceIndexAdminInsurancePoliciesRequest,
  indexAdminInsurancePolicyRequest
} from '../../requests/admin/adminInsurancePolicies';
import { InputRemoteSelect } from '../Utils/Select';

const InsurancePolicyRemoteSelector = ({ field, modelName }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const {
    options: insurancePolicies,
    selectedOption: selectedInsurancePolicy,
    fetchOptions: fetchInsurancePolicies
  } = useFetchForRemoteSelect({
    indexRequest: indexAdminInsurancePolicyRequest,
    debouncedIndexRequest: debounceIndexAdminInsurancePoliciesRequest,
    value: getIn(values, `${modelName}[insurancePolicyId]`),
    initialParams: {
      for_selector: true,
      is_valid: true,
      sort_column: 'created_at',
      sort_direction: 'asc'
    }
  });

  return (
    <InputRemoteSelect
      abbr
      {...field}
      isClearable
      label="Número de póliza"
      placeholder="Seleccionar póliza"
      defaultOptions={insurancePolicies}
      value={selectedInsurancePolicy}
      request={fetchInsurancePolicies}
      onChange={data => setFieldValue(field.name, data?.value || '')}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    />
  );
};

export default InsurancePolicyRemoteSelector;
