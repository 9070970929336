import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useFetchForRemoteSelect = ({ indexRequest, debouncedIndexRequest, value, initialParams, nestedDependency }) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [isFetching, setIsFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const initialFetch = () => {
    indexRequest({
      dispatch,
      params: {
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 1000,
        ...initialParams
      },
      successCallback: response => {
        setOptions(response.data.data);
        setIsFetching(false);
      }
    });
    setIsFetching(true);
  };

  const fetchOptions = (inputValue, callback) => {
    debouncedIndexRequest({
      dispatch,
      params: {
        query: inputValue,
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 1000,
        ...initialParams
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const handleCurrentValue = () => {
    const selected = options.find(option => option.value === parseInt(value, 10) || option.id === parseInt(value, 10));
    setSelectedOption(selected || false);
  };

  useEffect(handleCurrentValue, [value, options]);
  useEffect(initialFetch, [nestedDependency]);

  return { selectedOption, options, fetchOptions, setOptions, isFetching };
};

export default useFetchForRemoteSelect;
