import React from 'react';
import CoverSection from './CoverSection';
import SubjectMatterSection from './SubjectMatterSection';

const LeadFormSecondTab = ({ fromAdmin, modelName, requiredValues, action, userModule }) => {
  return (
    <>
      <CoverSection
        fromAdmin={fromAdmin}
        modelName={modelName}
        requiredValues={requiredValues}
        userModule={userModule}
      />

      <SubjectMatterSection modelName={modelName} action={action} />
    </>
  );
};

export default LeadFormSecondTab;
