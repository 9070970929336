import React from 'react';
import { getIn, useFormikContext } from 'formik';

import { InfoCardGeneral } from '../InfoCard';
import InfoCardRisk from '../InfoCard/InfoCardRisk';
import InfoCardSummaryCoverage from '../InfoCard/InfoCardSummaryCoverage';

const InsurancePolicyFormSummary = ({ isProposal, modelName }) => {
  const { values } = useFormikContext();
  const insurancePolicy = getIn(values, modelName);

  // const { acceptance, contract, contractFile, contractNumber, contractProposal, quotation } = insurancePolicy;
  // const createContractFromProposal = isProposal && contractNumber && contractFile;

  return (
    <>
      <p className="section-title">Resumen</p>
      <div className="insurance-policy-show-layout summary-main">
        <div className="general-information">
          <InfoCardGeneral isForm insurancePolicy={insurancePolicy} />
        </div>
        <div className="details-information">
          <InfoCardRisk insurancePolicy={insurancePolicy} />
          <InfoCardSummaryCoverage insurancePolicy={insurancePolicy} fromAdmin isProposal={isProposal} />
        </div>
        {/* <div className="side-components">
          {isProposal ? (
            <>
              <AttachedFile title="Cotización" attribute="quotation" modelName={modelName} file={quotation} />
              <AttachedFile title="Aprobación" attribute="acceptance" modelName={modelName} file={acceptance} />
              {createContractFromProposal && (
                <AttachedFile title="Póliza" attribute="contractFile" modelName={modelName} file={contractFile} />
              )}
            </>
          ) : (
            <>
              <AttachedFile title="Póliza" attribute="contract" modelName={modelName} file={contract} />
              <AttachedFile
                title="Propuesta"
                attribute="contractProposal"
                modelName={modelName}
                file={contractProposal}
              />
            </>
          )}
        </div> */}
      </div>
    </>
  );
};

// const AttachedFile = ({ attribute, file, modelName, title }) => {
//   const { values } = useFormikContext();
//   const savedFile = getIn(values, `${modelName}[${attribute}Info]`);

//   const fileLoaded = value => typeof value === 'object' && value;

//   return (
//     <StatisticCard
//       icon="notes"
//       variant="secondary-light"
//       title={title}
//       footerButton={
//         <CustomBtn
//           disabled={!file}
//           tooltipText={`${!file ? 'Sin documento asociado' : ''}`}
//           onClick={() => openFile(fileLoaded(file) || savedFile)}
//         >
//           Ver
//         </CustomBtn>
//       }
//     />
//   );
// };

export default InsurancePolicyFormSummary;
