import React from 'react';
import { Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { InputRemoteSelect } from '../Utils/Select';
import useFetchForRemoteSelect from '../../hooks/useFetchForRemoteSelect';
import { debounceIndexInsuranceCompanyRequest, indexInsuranceCompanyRequest } from '../../requests/insuranceCompanies';

const InsuranceCategoryCompanySelect = ({ companyAbbr, modelName }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const selectedInsuranceCategory = getIn(values, `${modelName}[insuranceCategoryCategory]`);

  const { options: companies, selectedOption: selectedCompany, fetchOptions: fetchCompanies } = useFetchForRemoteSelect(
    {
      indexRequest: indexInsuranceCompanyRequest,
      debouncedIndexRequest: debounceIndexInsuranceCompanyRequest,
      value: getIn(values, `${modelName}[insuranceCompanyId]`),
      initialParams: {
        category: selectedInsuranceCategory,
        for_selector: false
      },
      nestedDependency: selectedInsuranceCategory
    }
  );

  const handleCompanyChange = (field, data) => {
    if (data) {
      setFieldValue(field, data.value);
      setFieldValue(`${modelName}[insuranceCompanyName]`, data?.label);
    } else {
      setFieldValue(field, '');
      setFieldValue(`${modelName}[insuranceCompanyName]`, '');
    }
  };

  return (
    <>
      <Col lg={3} md={6}>
        <Field name={`${modelName}[insuranceCompanyId]`}>
          {({ field }) => (
            <InputRemoteSelect
              {...field}
              abbr={companyAbbr}
              isClearable={!companyAbbr}
              label="Aseguradora"
              defaultOptions={companies}
              value={selectedCompany}
              request={fetchCompanies}
              onChange={data => handleCompanyChange(field.name, data)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

export default InsuranceCategoryCompanySelect;
